import {
  AfterContentInit,
  Component,
  ContentChild,
  OnInit,
  Input,
  TemplateRef,
} from '@angular/core';
import { FormGroup, NgControl, NgModel } from '@angular/forms';

@Component({
  selector: 'ac-accenture-validators-message',
  templateUrl: './accenture-validators-message.component.html',
  styleUrls: ['./accenture-validators-message.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccentureValidatorsMessageComponent implements OnInit {
  @ContentChild('messageError', { static: false, read: TemplateRef })
  messageErrorTemplate!: TemplateRef<any>;
  @ContentChild(NgControl) ngControl!: NgControl;
  @Input() msgRequired: string = 'Field is required';
  @Input() msgEmail: string = 'Email is not valid';
  @Input() msgError: string = '';
  @Input() errorName: string = '';
  @Input() msgMin: string = '';
  @Input() msgMax: string = '';
  @Input() msgMinLength: string = '';
  @Input() msgMaxLength: string = '';
  @Input() msgPattern: string = '';
  @Input() templateMessage = false;
  @Input() useFormGroup: FormGroup | null = null;

  get invalid() {
    return Boolean(this.ngControl?.control?.invalid);
  }

  get touched() {
    return Boolean(this.ngControl.control?.touched);
  }

  get invalidFormGroup() {
    return this.useFormGroup && Boolean(this.useFormGroup.invalid);
  }

  constructor() {}

  ngOnInit(): void {}

  checkReferralCodeForm(ngControl: any) {
    if  (this.errorName == 'referralCodeError') {
      try {
        if  (ngControl?.control?.value != null) {
          if  (ngControl.control.value.toString().length > 0 && ngControl.control.value.toString().length < 4) {
            return true;
          }
        }
      } catch (error) {
        
      }
    }
    return false;
  }
}
