import { Pipe, PipeTransform } from '@angular/core';
import { SelectComponent } from './select.component';

@Pipe({
  name: 'displayValue',
})
export class DisplayValuePipe implements PipeTransform {
  constructor(readonly selectComponent: SelectComponent) {}
  transform(value: any): string {
    if (!Boolean(value)) {
      return '';
    } else if (this.selectComponent.optionLabel) {
      return value[this.selectComponent.optionLabel];
    } else if (typeof value === 'string') {
      return value;
    }
    return value;
  }
}
