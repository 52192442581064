import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import {
  AdditionalInformation,
  CurrentResidence,
  CurrentResidenceLang,
  EducationLevel,
  EducationLevelLang,
  IBaseOccupationGroup,
  IBaseSubOccupation,
  MaritalStatus,
  MaritalStatusLang,
} from '../../interfaces';
import { AppStateService } from '../../../app-state.service';
import { HttpService } from '@web-di-ld/apps/http';
import { LOAN_PRODUCT_TYPE } from '../../constant/base.enum';

// service
@Injectable({
  providedIn: 'root',
})
export class AdditionalInformationService {
  userInfo: any = [];

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService,
    private appStateService: AppStateService
  ) {}

  getEducation(lang: string): Promise<EducationLevel[]> {
    return this.httpService
      .get<EducationLevelLang[]>(`/lfm-lookup/app/v1/demography/education`)
      .toPromise()
      .then((response: any) => {
        return response?.data?.education;
      })
      .catch((error) => {
        this.handleError(error);
        return [];
      });
  }

  getCurrentResidence(lang: string): Promise<CurrentResidence[]> {
    return this.httpService
      .get<CurrentResidenceLang[]>(
        `/lfm-lookup/app/v1/demography/current-residence`
      )
      .toPromise()
      .then((response: any) => {
        return response?.data?.currentResidenceList;
      })
      .catch((error) => {
        this.handleError(error);
        return [];
      });
  }

  getMaritalStatus(lang: string): Promise<MaritalStatus[]> {
    return this.httpService
      .get<MaritalStatusLang[]>(`/lfm-lookup/app/v1/demography/marital-status`)
      .toPromise()
      .then((response: any) => {
        return response?.data?.marital;
      })
      .catch((error) => {
        this.handleError(error);
        return [];
      });
  }

  async getOccupationGroup(occupationSector: string) {
    const productDetail = await this.appStateService.ProductDetail$.pipe(
      take(1)
    ).toPromise();
    return this.httpService
      .get<IBaseOccupationGroup>(
        `/lfm-lookup/app/v1/demography/occupation/products/${productDetail?.productCode}/sector/${occupationSector}/group`
      )
      .toPromise()
      .then((response: any) => {
        return response.data.occupationGroupList;
      })
      .catch((error) => {
        this.handleError(error);
        return [];
      });
  }

  async getSubOccupation(
    occupationSector: string,
    occupationGroupCode: string
  ) {
    const productDetail = await this.appStateService.ProductDetail$.pipe(
      take(1)
    ).toPromise();
    return this.httpService
      .get<IBaseSubOccupation>(
        `/lfm-lookup/app/v1/demography/occupation/products/${productDetail?.productCode}/sector/${occupationSector}/group/${occupationGroupCode}/suboccupation`
      )
      .toPromise()
      .then((response: any) => {
        return response.data.subOccupationList;
      })
      .catch((error) => {
        this.handleError(error);
        return [];
      });
  }

  getStateSalary(): any {
    const state = this.appStateService.snapshot.loanApplication;
    if (state) {
      return state.salary;
    }
    return null;
  }

  getIsExisting(): any {
    const state = this.appStateService.snapshot.customerDetail;
    if (state) {
      return state.isExisting;
    }
    return null;
  }

  getStateAddtionalInformation(): any {
    return this.appStateService.snapshot.additionalInformation;
  }

  clickNext(additionalInformation: AdditionalInformation) {
    this.appStateService.updateStateAdditionalInformation(
      additionalInformation
    );
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
