import { Injectable } from '@angular/core';
import { IPromotion, ITemplate } from '../../interfaces';
import { AppStateService } from '../../../app-state.service';
import { HttpService } from '@web-di-ld/apps/http';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

// service
@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(
    private appState: AppStateService,
    private httpService: HttpService,
    private httpClient: HttpClient
  ) {}

  public async getPromotion(): Promise<ITemplate> {
    // return this.httpService
    //   .get<ProductDetail>(`assets/data/mock-data/product-detail.json`)
    // this.appState
    const promotionBanner = this.appState.snapshot.promotionBanner;
    // const promotionBanner = { campNo: 'campNo', bannerType: 'PROMO' };

    // let url = `/lfm-banners/app/v1/banners/${promotionBanner.campNo}/detail`;
    // if (promotionBanner.bannerType == 'PROMO') {
    //   url = `/lfm-banners/app/v1/smartpromo/${promotionBanner.campNo}/detail`;
    // }
    // console.log(url);
    const url = `/lfm-banners/app/v1/campaigns/${promotionBanner.campNo}`;
    return this.httpService
      .get<ITemplate>(url)
      .toPromise()
      .then((response: any) => {
        this.appState.updateStatePromotion(response.data);
        return response.data;
      })
      .catch(async (error) => {
        console.error(error);
      });
  }

  public async getPromotionMock(): Promise<ITemplate> {
    const promotionBanner = this.appState.snapshot.promotionBanner;

    let url = `/assets/data/mock-data/get-smartpromo-detail-temp1.json`;
    if (promotionBanner.bannerType == 'PROMO') {
      url = `/assets/data/mock-data/get-smartpromo-detail-temp2.json`;
    }
    return this.httpClient
      .get<ITemplate>(url)
      .toPromise()
      .then((response: any) => {
        this.appState.updateStatePromotion(response.data);
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
}
