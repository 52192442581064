export enum LOAN_ACCESS_TYPE {
  CALCULATION,
  LOAN_APPLY,
}

export enum LOAN_PRODUCT_TYPE {
  SMARTMONEY = 'DL_SMARTMONEY',
  PAYDAY = 'DL_PAYDAY',
  THUNGNGERN = 'DL_THUNGNGERN',
  _5PLUSTL = 'DL_5PLUS_TL',
  _5PLUSOD = 'DL_5PLUS_OD',
  TOPUP_THUNGNGERN = 'TU_THUNGNGERN',
  TOPUP_SMARTMONEY = 'TU_SMARTMONEY',
  TOPUP_5PLUSTL = 'TU_5PLUS_TL',
}

export enum LOAN_RATE_TYPE {
  FIXEDMAX = 'FIXEDMAX',
  FIXEDRANGE = 'FIXEDRANGE',
  INDEXMAX = 'INDEXMAX',
  INDEXRANGE = 'INDEXRANGE',
}

export enum NAVIGATION_PATH {
  SCREENWEB = 'SCREENWEB',
  DEEPLINK = 'DEEPLINK',
  WEBVIEW_INTERNAL = 'WEBVIEW_INTERNAL',
  WEBVIEW_EXTERNAL = 'WEBVIEW_EXTERNAL',
}

export enum BANNER_TYPE {
  BANNER = 'BANNER',
  PROMO = 'PROMO',
}

export enum BANNER_POSITION {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum BE_ERROR_CODE {
  PRODUCT_EXPIRED = 'OFFE500',
  NO_PAYROLL_ACCOUNT = 'OFFE580',
  PRODUCT_CLOSED = 'OFFE110',
  LOAN_APP_EXPIRED = 'APPE401',
  APPLY_REJECT_LIMIT = 'OFFE521',
  APPLY_DAILY_LIMIT = 'OFFE522',
  APPLY_MONTHLY_LIMIT = 'OFFE523',
  INVALID_CBS_DATA = 'OFFE810',
  CID_NOT_APPLICABLE = 'OFFE311',
  PRODUCT_NOT_ELIGIBLE = 'OFFE414',
  ACC_OPEN_IN_PERIOD = 'OFFE590',
  CBS_MANDATORY_FIELD_MISSING = 'OFFE585',
  CBS_SERVICE_DOWN = 'OFFE801',
  ERROR_EXISTING_LOAN = 'OFFE581',
  ERROR_DISBURSEMENT_ACC = 'OFFE582',
  ERROR_DIRECT_DEBIT_ACC = 'OFFE583',
  TOPUP_CREDIT_LIMIT_LESS_THAN_MINIMUM = 'OFFE533',
  CUSTOMER_MANDATORY_FIELD_MISSING = 'APPE585',
  CUSTOMER_CBS_MAILING_ADDRESS_INVALID = 'APPE811',
  NOT_ELIGIBLE_ANY_PRODUCTS = 'OFFE550',
  INOPROGRESS_OTHER_PRODUCT = 'OFFE540',
  MAX_PAYMENT_TERM_INVALID = 'OFFE534',
  COMPARE_CREDIT_LIMIT_INVALID = 'OFFE535',
}

export enum FE_ERROR_CODE {
  GENERIC = 'GENERIC_ERROR',
  PEP_OVERSEA_YES = 'PEP_OVERSEA_YES',
  INVALID_AGE = 'INVALID_AGE',
  MISSING_PERSONAL_INFO = 'MISSING_PERSONAL_INFO',
  INVALID_NATIONALITY = 'INVALID_NATIONALITY',
  INVALID_CBS_DATA = 'INVALID_CBS_DATA',
  COUNTRY_IS_NOT_TH = 'COUNTRY_IS_NOT_TH',
  CID_NOT_APPLICABLE = 'CID_NOT_APPLICABLE',
  NCB_CONSENT_NOT_APPLICABLE = 'NCB_CONSENT_NOT_APPLICABLE',
  NPER_IS_NAN = 'NPER_IS_NAN',
  AIS_CONSENT_CONFIRMED_CAMPAIGN_END = 'AIS_CONSENT_CONFIRMED_CAMPAIGN_END',
  AIS_KTB_TERM_AND_CON_USER_REJECT = 'AIS_KTB_TERM_AND_CON_USER_REJECT',
  AIS_CONSENT_USER_REJECT = 'AIS_CONSENT_USER_REJECT',
  AIS_OTP_INCORRECT = 'AIS_OTP_INCORRECT',
  AIS_OTP_EXPIRED = 'AIS_OTP_EXPIRED',
  AIS_OTP_EXCEEDED = 'AIS_OTP_EXCEEDED',
}

export enum EDIT_INFO_TYPE {
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  BUSINESS_INFO = 'BUSINESS_INFO',
}

export enum GA_EVENT_ACTION {
  MIGRATION = 'Migration',
  CLICK = 'Click',
  SLIDE = 'Slide',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  AUTHENTICATE = 'Authenticate',
  VIEW = 'View',
  READPDF = 'ReadPDF',
}

export enum GA_EVENT_CATEGORY {
  PRODUCT_SHELF = 'DigitalLending_LoanWebProductShelf',
  LOAN_APPLY = 'DigitalLending_LoanWebLoanInfo',
  PRODUCT_DETAIL = 'DigitalLending_LoanWebSaleSheetPDF',
  LOAN_CALCULATE = 'DigitalLending_LoanWebLoanCalculation',
  PERSONAL_INFORMATION = 'DigitalLending_LoanWebPersonalInformation',
  ADDITIONAL_INFORMATION = 'DigitalLending_LoanWebAdditionalInformation',
  EDIT_INFORMATION = 'DigitalLending_LoanWebUpdateInformation',
  CONFIRM_DETAIL = 'DigitalLending_LoanWebConfirmDetails',
  MARKETING_CONSENT = 'DigitalLending_LoanWebModelConsent',
  NCB_CONSENT = 'DigitalLending_LoanWebNCBConsent',
  PROMOTION = 'DigitalLending_LoanWebMarketing',
  TOPUP_LOAN_DETAIL = 'DigitalLending_LoanWebTopupInfo',
  TOPUP_ADDITIONAL_DETAIL = 'DigitalLending_LoanWebTopupAddInfo',
  TOPUP_CONFIRMATION = 'DigitalLending_LoanWebTUConfirm',
  EDIT_MAILING_ADDRESS = 'DigitalLending_LoanWebUpdateMailing',
  RATING = 'DigitalLending_LoanWebRating',
  TOPUP_REQUEST_CREDIT = 'DigitalLending_LoanWebTopupInfoOpt',
  AIS_T_AND_C = 'DigitalLending_LoanWebAIST&C',
  AIS_OTP = 'DigitalLending_LoanWebAISOTP',
  AIS_OTP_INCORRECT_OTP = 'DigitalLending_LoanWebAISOTPError',
  AIS_OTP_EXCEEDED_LIMIT = 'DigitalLending_LoanWebAISOTPErrorLimit',
  AIS_OTP_EXPIRED = 'DigitalLending_LoanWebAISOTPErrorExpire',
  AIS_CONSENT = 'DigitalLending_LoanWebAISConsent',
  CP_MISMATCH_CON = 'DigitalLending_LoanWebCPMismatchCon',
}

export enum APPFLYER_EVENT_LABEL {
  LOAN_APPLY = 'Next_KrungthaiJaiPump_ProductHighlight',
  LOAN_APPLY_FILLED_APPLY = 'Next_KrungthaiJaiPump_Filldata_Apply',
  LOAN_APPLY_PERSONAL_DATA_CONFIRM = 'Next_KrungthaiJaiPump_Confirm_PersonalData',
  LOAN_APPLY_ANOTHER_LOAN_FILL_DATA = 'Next_KrungthaiJaiPump_Filldata_AnotherLoan',
  LOAN_APPLY_NCB_CONSENT_CONFIRM = 'Next_KrungthaiJaiPump_NCBconsent_Confirm',
  LOAN_APPLY_SMART_MONEY_PARTNER_TC = 'Next_KrungthaiJaiPump_PartnerT&C',
  LOAN_APPLY_SMART_MONEY_PARTNER_TC_CONFIRM = 'Next_KrungthaiJaiPump_PartnerT&C_Next',
}

export enum GA_CUSTOM_DIMENSION {
  PAYMENT_DESTINATION = 3,
  INFORMATION = 4,
  HTTP_STATUS_CODE = 5,
  ERROR_CODE = 6,
  IS_FIN_TXN_ERROR = 7,
  ERROR_TITLE = 8,
  ERROR_DISPLAY_TYPE = 9,
  SOF_PRODUCT_CODE = 10,
  FEE = 11,
  SEARCH_TERM = 12,
  NOTIFICATION_DATE = 13,
  BILLER_NAME = 15,
  BILLER_CATEGORY_PRODUCT_TYPE = 16,
  SCHEDULE_TYPE = 17,
  SCHEDULE_DURATION = 18,
  CAMPAIGN_NOTI_BANNER_ID = 19,
  ITEM_ORDER = 20,
  MIGRATION = 21,
  CORRELATION_ID = 22,
  FUND_CODE = 23,
  FUND_TYPE = 24,
  PARTNER_APP = 25,
  REGISTRATION_EKYC = 26,
  NDID = 27,
  SWIFT_CODE = 28,
  INVESTMENT_OBJECT = 29,
  MONTHLY_INCOME = 30,
  SOURCE_OF_INCOME = 31,
  COUNTRY = 32,
  OCCUPATION = 33,
  BUSINESS_TYPE = 34,
  AMC_CODE = 35,
  COMPANY_ABBR = 36,
  REFERRAL_CODE = 37,
  PRODUCT_TYPE = 38,
  POSITION = 39,
  CAMPAIGN_TYPE = 40,
}

export enum ERROR_TYPE {
  FULLSCREEN = 'FULL_SCREEN_ERROR',
  POPUP = 'POPUP_SCREEN_ERROR',
  GENERIC_ERROR = 'GENERIC_ERROR',
}

export enum SCREEN_MODE {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum AIS_ERROR_CODE {
  BLOCKED_USER = 'AISE521',
  INCORRECT_PWD = 'AISE511',
  BLOCKED_USER_3_ATTEMPTS = 'AISE512',
  CONSENT_CONFIRM_OTP_EXPIRED = 'AISE513',
  TOKEN_CONSENT_PROFILE_EMPTY = 'AISE501',
  CONSENT_VERSION_NOT_MATCH = 'AISE502',
  ELIGIBILITY_CHECK_FAILED_CONSENT_VERSION = 'AISE530',
  CUSTOMER_PROFILE_NOT_FOUND = 'AISE531',
}
