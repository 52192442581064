import { Component, Input } from '@angular/core';

@Component({
  selector: 'web-di-ld-apply-error',
  templateUrl: './apply-error.component.html',
  styleUrls: ['./apply-error.component.scss'],
})
export class ApplyErrorComponent {
  @Input() path = '';
  @Input() title = '';
  @Input() description = '';

  constructor() {}
}
