<ng-container *transloco="let t; read: 'titleHeader'">
  <div *ngIf="title" class="header card p-ai-center">
    <div class="p-d-flex ">
      <img
        *ngIf="title != t('confirmSummary')"
        src="/assets/images/arrowBack@3x.png"
        class="arraw-back"
        (click)="navigateTo(url)"
      />
      <div class="title" *ngIf="title">{{ title }}</div>
      <div *ngIf="title == t('confirmSummary')" class="close-screen">
        <img
          src="/assets/images/foundation-icon-simple-system-close.svg"
          (click)="close()"
        />
      </div>
      <div
        *ngIf="share == 'true'"
        class="share-button"
        (click)="navigatorShare()"
      >
        {{ t('share') }}
      </div>
    </div>
  </div>
</ng-container>
