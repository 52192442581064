<p-card (click)="onNavigateToCalculation()" id="cdrProductShelfCalculationMenu">
  <div class="p-d-flex p-ai-center p-jc-between">
    <div>
      <div class="title" id="divProductShelfCalcutationMenuTitle">
        {{ 'loanProductShelf.calculatoinMenu.title' | transloco }}
      </div>
      <div class="description" id="divProductShelfCalcutationMenuDescription">
        {{ 'loanProductShelf.calculatoinMenu.description' | transloco }}
      </div>
    </div>
    <div class="p-d-flex p-ai-center">
      <img
        class="ic-calculator"
        src="assets/images/icon_calculator.svg"
        alt=""
      />
      <img
        src="/assets/images/icon_arrow_right_default.png"
        class="arrow-right"
      />
    </div>
  </div>
</p-card>
