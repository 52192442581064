import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccentureValidatorsModule } from '@web-di-ld/accenture-validators';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';

import { SwiperModule } from 'swiper/angular';

import { PinchZoomModule } from 'ngx-pinch-zoom';

import { TabViewModule } from 'primeng/tabview';

import { TranslocoRootModule } from '../../../core/transloco-root.module';
import { ImageModule } from '../../directives/image/image.module';
import { PureModule } from '../../pipe/pure/pure.module';

import { ApplyErrorComponent } from './apply-error/apply-error.component';
import { BannerComponent } from './banner/banner.component';
import { ButtonComponent } from './button/button.component';
import { CardProductShelfComponent } from './card-product-shelf/card-product-shelf.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ErrorComponent } from './error/error.component';
import { HeaderComponent } from './header/header.component';
import { ModalComponent } from './modal/modal.component';
import { PromoComponent } from './promo/promo.component';
import { VerifyComponent } from './verify/verify.component';
import { DirectiveModule } from '../../directives/directive.module';
import { StepComponent } from './step/step.component';
import { StepsModule } from 'primeng/steps';
import { DividerModule } from 'primeng/divider';
import { ReplaceNullWithTextModule } from '../../pipe/replace-null-with-text/replace-null-with-text.module';

const modules = [
  FormsModule,
  ReactiveFormsModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  InputNumberModule,
  SliderModule,
  SelectButtonModule,
  CheckboxModule,
  RadioButtonModule,
  PdfViewerModule,
  InputTextModule,
  DialogModule,
  TranslocoRootModule,
  CommonModule,
  AccentureValidatorsModule,
  SwiperModule,
  PinchZoomModule,
  PureModule,
  TabViewModule,
  ImageModule,
  DirectiveModule,
  StepsModule,
  DividerModule,
  ReplaceNullWithTextModule,
];

const components = [
  ButtonComponent,
  CheckboxComponent,
  ModalComponent,
  ApplyErrorComponent,
  CardProductShelfComponent,
  HeaderComponent,
  VerifyComponent,
  ErrorComponent,
  BannerComponent,
  PromoComponent,
  StepComponent,
];
@NgModule({
  declarations: components,
  imports: modules,
  exports: [...components, ...modules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiModule {}
