import { BANNER_TYPE, NAVIGATION_PATH } from '../constant/base.enum';

export interface IBaseBanner {
  autoSwipeTime: number;
  content: IPromotionBanner[];
}
export interface IPromotionBanner {
  campNo: string;
  position: string;
  priority: number;
  navigationPathType?: NAVIGATION_PATH;
  navigationPath: string;
  imgURL: string;
  startDate: string;
  endDate: string;
  tagImgURL: string;
  tagStartDate: string;
  tagEndDate: string;
  isCallTemplate: boolean;
  order?: number;
  bannerType?: BANNER_TYPE;
}
