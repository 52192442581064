export interface IFlow {
  flow?: FLOW_STATE;
  enterFlow?: FLOW_STATE;
  state?: FLOW_STATE;
  isTopup?: boolean;
}

export enum FLOW_STATE {
  COMFIRM = 'COMFIRM',
  CALCULATE = 'CALCULATE',
  SHELF = 'SHELF',
  CLEAR = 'CLEAR',
  LOANAPPLY = 'LOANAPPLY',
  PERSONAL = 'PERSONAL',
  ADDITIONAL = 'ADDITIONAL',
  MODEL = 'MODEL',
  NCB = 'NCB',
  RATING = 'RATING',
  EDITINFO = 'EDITINFO',
  TOPUP_REQUEST_CREDIT = 'TOPUP_REQUEST_CREDIT',
  TOPUP_DETAIL = 'TOPUP_DETAIL',
  TOPUP_ADDITIONAL = 'TOPUP_ADDITIONAL',
  TOPUP_CONFIRM = 'TOPUP_CONFIRM',
  SALESHEET = 'SALESHEET',
}
