import { Component, Input, OnInit } from '@angular/core';
import { StepService } from 'apps/di-ld/src/app/core/services/shared/step.service';

@Component({
  selector: 'web-di-ld-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnInit {
  constructor(public stepService: StepService) {}

  ngOnInit(): void {}
}
