import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LOAN_RATE_TYPE } from 'apps/di-ld/src/app/core/constant/base.enum';
import { IProductShelf } from 'apps/di-ld/src/app/core/interfaces';
import dayjs from 'dayjs';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'web-di-ld-products-topup',
  templateUrl: './products-topup.component.html',
  styleUrls: ['./products-topup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductsTopupComponent implements OnInit {
  @Input() items!: Array<IProductShelf>;
  @Input() autoSwipeTime!: number;
  @Output() onClick = new EventEmitter();

  isMulti: boolean = false;

  public LOAN_RATE_TYPE = LOAN_RATE_TYPE;
  public interest: any = {
    enableSpecial: false,
    indexRateType: '',
    maxInterestRate: 0,
    maxSpecialRate: 0,
    minInterestRate: 0,
    minSpecialRate: 0,
    rateType: undefined,
  };

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.isMulti = this.items.length > 1 ? true : false;

    for (const item of this.items) {
      item.limitTimeMsg = this.getLimitTimeMsg(item.expirationDate ?? '');
      item.interest = {
        enableSpecial: item.interest.enableSpecial,
        indexRateType: item.interest.indexRateType,
        maxInterestRate: item.interest.maxInterestRate as number,
        maxSpecialRate: item.interest.maxSpecialRate as number,
        minInterestRate: item.interest.minInterestRate as number,
        minSpecialRate: item.interest.minSpecialRate as number,
        rateType: item.interest.rateType,
      };
    }
  }

  public onClickButton(item: IProductShelf) {
    this.onClick.emit(item);
  }

  private getLimitTimeMsg(expirationDate: string) {
    let msg = '';
    if (expirationDate) {
      const endDate = dayjs(dayjs(expirationDate).format('YYYY-MM-DD'));
      const currentDate = dayjs(dayjs().format('YYYY-MM-DD'));
      const dateAmount = endDate.diff(currentDate, 'day');
      if (dateAmount <= 5 && dateAmount > 0) {
        msg = this.translocoService.translate(
          'loanProductShelf.topupDetail.limitTime',
          { value: dateAmount }
        );
      } else if (dateAmount === 0) {
        msg = this.translocoService.translate(
          'loanProductShelf.topupDetail.lastDay'
        );
      }
    }
    return msg;
  }

  public getAbsolute(value: number) {
    return Math.abs(value);
  }
}
