import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmFeedback } from '../../interfaces';
import { HttpService } from '@web-di-ld/apps/http';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {}

  getConfirmFeedbackStar(lang: string): Promise<ConfirmFeedback[]> {
    return this.httpClient
      .get<ConfirmFeedback[]>(`assets/data/lookup/confirm-feedback.json`)
      .toPromise()
      .then((response: any) => {
        return response?.feedbackStar?.[lang];
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }
  getConfirmFeedbackFiveStar(lang: string): Promise<ConfirmFeedback[]> {
    return this.httpClient
      .get<ConfirmFeedback[]>(`assets/data/lookup/confirm-feedback.json`)
      .toPromise()
      .then((response: any) => {
        return response?.feedbackFiveStar?.[lang];
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  postComfirm(rate: number, suggestion: string, applicationRef: string) {
    return this.httpService
      .post(
        `/lfm-survey/app/v1/rating/add`,
        {
          applicationRef: applicationRef,
          rate: rate,
          suggestion: 'suggestion',
        },
        false
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }
}
