import exp from 'constants';
import { LOAN_PRODUCT_TYPE } from '../constant/base.enum';
import { CustomerDetail } from './base.interface';

export interface BaseProductDetail {
  code: string;
  message: string;
  data: IProductDetail;
}

export interface BaseTopupProductDetail {
  code: string;
  message: string;
  data: ITopupProductDetail;
}

export interface LoanApplyInfo {
  occupation: any;
  salary: number;
  amount: number;
  installment: string;
  confirm?: string[];
}

export interface IProductDetail {
  appTemplVersion?: number;
  cifNo: string;
  productTypeCode: string;
  productMarketCode: string;
  productCode: LOAN_PRODUCT_TYPE;
  productName: string;
  minCreditLimit: number;
  maxCreditLimit: number;
  minMonthlyIncome: number;
  paymentIntervalAmount: number;
  creditLimitType: string;
  paymentTerms: number[];
  maxPaymentTerm: number;
  interest: Interest;
  saleSheetUrl: string;
  productHighlightBullets: StaticMessage[];
  productDetailBullets: StaticMessage[];
  placeHolder1: IPlaceholder;
  placeHolder2: IPlaceholder;
  isIncomeDriven: boolean;
  isOccupationDriven: boolean;
  [any: string]: any;
}

export interface ITopupProductDetail extends IProductDetail {
  existingLoanAccount: string;
  disbursementAccount: string;
  disbursementAccountProductGrp: string;
  disbursementAccountBranchCode: string;
  disbursementAccountCostCenter: string;
  directDebitAccount: string;
  directDebitAccountProductGrp: string;
  directDebitAccountBranchCode: string;
  directDebitAccountCostCenter: string;
  currentDate: string;
  outStandingBalance: number;
  accruedInterest: number;
  feeAndOtherPayments: number;
  payOffAmount: number;
  offeredTopUpCreditLimit: number;
  estimatedTopUpCreditLimit: number;
  interestRate: number;
  indexRateType: string;
  indexRate: string;
  rateType: string;
  installmentAmount: number;
  installmentPeriod: number;
  uploadAllDocument: boolean;
  uploadDocument: boolean;
  uploadStatment: boolean;
  occupationApply: string;
  income: string;
  allPayRollAccount?: string[];
  payRollDuePayment?: number;
  customerData: CustomerDetail;
  productCodeDynamicFieldMapping: ProductCodeDynamicFieldMapping;
}
export interface IPlaceholder {
  title: string;
  message: string;
}

export interface IInterestRate {
  minInterestRate: number;
  maxInterestRate: number;
  updateDate: string;
  rate: IRate;
}

export interface IRate {
  rateType: string;
  index_rate: string;
}

export interface StaticMessage {
  message: string;
}

export interface BaseLoanAttribute {
  code: string;
  message: string;
  data: LoanAttribute;
}

export interface LoanAttribute {
  minCreditLimit: number;
  limitIncomeFactor: number;
  maxCreditLimit: number;
  maxCreditLimitSlip: number;
  interest: IInterest;
  isPayslipRequired: boolean | null;
}

export interface IInterest {
  minInterestRate: number;
  maxInterestRate: number;
  updateDate: string;
  rateType: string;
  indexRate: number;
  indexRateType: string;
}
export interface ProductDetailAbstract {
  productTypeCode?: string;
  productMarketCode?: string;
  placeholder1?: string;
  placeholder2?: string;
  appTemplVersion?: number;
  minSalaryAllowed?: any;
  maxSalaryAllowed?: any;
  step?: any;
  times: MapCode[];
  installmentTerm?: InstallmentTerm[];
  checkAvgIncome: number;
  checkEnteredIncome: number;
}
export interface InstallmentTerm {
  mapping?: string;
  code: string;
  value: InstallmentTermValue[];
}

export interface InstallmentTermValue {
  name: string;
  code: string;
  inactive?: boolean;
}

export interface ProductConfig extends ProductDetailAbstract {}

export interface CalculateConfig extends ProductDetailAbstract {
  defaultMaxCreditLimit?: number;
  defaultMinCreditLimit?: number;
  defaultInterestRate?: number;
}

export interface ProductDetail extends IProductDetail {}
export interface IBaseOccupation {
  code: string;
  message: string;
  data: IOccupation;
}
export interface IBaseTopupOccupation {
  code: string;
  message: string;
  data: ITopupOccupation;
}
export interface ITopupOccupation {
  occupationList: IOccupationList[];
}
export interface IOccupation {
  occupationSector: IOccupationSector[];
}
export interface IOccupationSector {
  occupationSector: string;
  occupationSectorDescription: string;
  isPrefill?: boolean;
}

export interface IOccupationList {
  occupationCode: string;
  occupationDescription: string;
  occupationDescriptionEn: string;
  occupationDescriptionTh: string;
  isPrefill?: boolean;
}
export interface IBaseOccupationGroup {
  code: string;
  message: string;
  data: IOccupationGroup;
}
export interface IOccupationGroup {
  occupationGroupList: IOccupationGroupList[];
}
export interface IOccupationGroupList {
  occupationGroupCode: string;
  occupationGroup: string;
  haveSubOccupation: boolean;
  subOccupationCode: string;
}
export interface IBaseSubOccupation {
  code: string;
  message: string;
  data: ISubOccupation;
}
export interface ISubOccupation {
  subOccupationList: ISubOccupationList[];
}

export interface ISubOccupationList {
  subOccupationCode: string;
  subOccupation: string;
}
export interface Bullet {
  th?: Message[];
  en?: Message[];
  [key: string]: any;
}

export interface Message {
  message: string;
}
export interface CampaignDetail {
  cifNo: string;
  productCode: string;
  productName: string;
  minCreditLimit: number;
  maxCreditLimit: number;
  creditLimitType: string;
  paymentTerms: number[];
  interest: Interest;
  saleSheetUrl: string;
  productHighlightBullets: Message[];
  productDetailBullets: Message[];
  [key: string]: any;
}

interface Interest {
  minInterestRate: number;
  maxInterestRate: number;
  updateDate: string;
  rate: Rate;
}

interface Rate {
  rateType: string;
  index_rate: number;
}

export interface MapCode {
  code: string;
  value: any;
  mapping: string;
}

export interface LoanApplication {
  productName: string;
  loanAmount: string;
  interestRate: string;
  estimatesMonthlyInstallment: string;
  paymentTerm: string;
  loanReceivingAccount?: string;
  branch?: string;
  fee?: string;
  loanPurpose?: string;
  moreFinancialInstitutions?: string;
  salary?: string;
  occupation?: IOccupationSector;
  confirm: string[];
  productConfig?: ProductConfig;
  calculateConfig?: CalculateConfig;
  customerDetail?: CustomerDetail;
  campaignDetail?: CampaignDetail;
  monthlyInstallmentAmountMax?: number;
  monthlyInstallmentAmountMin?: number | null;
  uploadPayslip?: boolean;
  payPerMonthDisplay?: string | null;
  interestDisplay?: string;
  interestRateMin?: number | null; //double
  interestRateMax?: number; //double
  [key: string]: any;
  referralCode?: string;
}

export interface ILimitValidate {
  availableDate?: string;
  cutOffDay: number;
  maxSubmission?: number;
}

export interface ProductCodeDynamicFieldMapping {
  dynamicTopupConfirmationScreenElement: DynamicTopupConfirmationScreenElement[];
}

export interface DynamicTopupConfirmationScreenElement {
  elementName: string;
}

export interface TopupLoanApplication extends LoanApplication {
  currentDate: string;
  interestDisplay: string;
  maskAcc: string;
  existingLoanAccount: string;
  currentDateDisplay: string;
  isIndex: boolean;
  payOffAmount: number;
  estimatedTopUpCreditLimit: number;
  indexRateType?: string;
  indexRate?: number;
  outstandingBalance: number;
  accruedInterest: number;
  feeAndOtherPayments: number;
  isCheckAcknowledgement?: boolean;
  [any: string]: any;
}
