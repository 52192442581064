import { Injectable } from '@angular/core';
import { ProductDetail } from '../../interfaces';
import { AppStateService } from '../../../app-state.service';
import { HttpService } from '@web-di-ld/apps/http';
import { environment } from '@environments/environment';

// service
@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private appState: AppStateService,
    private httpService: HttpService
  ) {}

  public async getProductDetail(campaignId: string): Promise<ProductDetail> {
    // return this.httpService
    //   .get<ProductDetail>(`assets/data/mock-data/product-detail.json`)
    let url = '/api/applications/v1/product-details';
    if (environment.mode !== 'sit' && environment.mode !== 'dev') {
      url += '/' + campaignId;
    }
    return this.httpService
      .get<ProductDetail>(url)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  public updateProductDetail(productDetail: ProductDetail) {
    this.appState.updateStateProductDetail(productDetail);
  }
}
