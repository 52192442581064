import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pure',
  pure: true,
})
export class PurePipe implements PipeTransform {
  transform(fn: Function, ...arg: any[]): any {
    return fn(...arg);
  }
}
