import { Injectable } from '@angular/core';
import { IFlow, FLOW_STATE } from '../../interfaces';
import { AppStateService } from '../../../app-state.service';

// service
@Injectable({
  providedIn: 'root',
})
export class FlowService {
  constructor(private appState: AppStateService) {}

  public setFlow(flow: FLOW_STATE, enterFlow?: FLOW_STATE, state?: FLOW_STATE) {
    let current_flow: IFlow = { flow: flow };
    if (enterFlow) {
      current_flow = { ...current_flow, enterFlow };
    }
    if (state) {
      current_flow = { ...current_flow, state };
    }
    this.appState.updateStateFlow(current_flow);
  }

  public setState(state: FLOW_STATE) {
    let flow: IFlow = { state: state };
    this.appState.updateStateFlow(flow);
  }

  public setTopupFlow(isTopup: boolean) {
    let flow: IFlow = { isTopup: isTopup };
    this.appState.updateStateFlow(flow);
  }

  public getFlow() {
    return this.appState.snapshot.flow;
  }
}
