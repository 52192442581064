<swiper [slidesPerView]="2" [spaceBetween]="10" class="mySwiper">
  <ng-container *ngFor="let item of items; let i = index">
    <ng-template swiperSlide>
      <div class="image-promo">
        <ng-container
          *ktbLoadImage="
            let safeUrl of item.imgURL;
            let complete = complete;
            let error = error
          "
        >
          <ng-container *ngIf="complete">
            <img
              [src]="safeUrl"
              class="promo-image"
              (click)="onClickBanner(item, i)"
            />
          </ng-container>

          <ng-container *ngIf="error">
            <img
              src="/assets/images/placeholders/promotion-placeholder.png"
              class="promo-image"
              (click)="onClickBanner(item, i)"
            />
          </ng-container>

          <ng-container
            *ktbLoadImage="
              let safeUrl of item.tagImgURL;
              let complete = complete;
              let error = error
            "
          >
            <ng-container *ngIf="complete">
              <img
                [src]="item.tagImgURL"
                alt=""
                class="tag-image"
                *ngIf="isShowTag(item)"
              />
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</swiper>
