import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JsInterfaceService } from '..';
import { DeeplinkBody } from '../../interfaces/js-interface.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private jsInterfaceService: JsInterfaceService
  ) {}

  goToScreenWeb(path: string) {
    this.router.navigate([path]);
  }

  goToDeepLink(link: string) {
    if (link !== '') {
      window.open(link);
    }
  }

  goToDeepLinkJs(link: string) {
    if (link !== '') {
      this.jsInterfaceService.openDeeplinkNext(link);
    }
  }

  goToWebviewInternal(link: string) {
    this.jsInterfaceService.openHiddenWebUrl(link);
  }

  goToWebviewExternal(path: string) {
    if (path !== '') {
      // window.open(path, '_blank');
      this.jsInterfaceService.openExternalWebview(path);
    }
  }

  // no navigation path type
  goToLink(path: string) {
    if (path !== '') {
      this.isValidHttpUrl(path)
        ? this.goToWebviewInternal(path)
        : this.goToDeepLink(path);
    }
  }

  goToLinkJs(path: string) {
    if (path !== '') {
      this.isValidHttpUrl(path)
        ? this.goToWebviewInternal(path)
        : this.goToDeepLinkJs(path);
    }
  }

  isValidHttpUrl(path: string) {
    let url;

    try {
      url = new URL(path);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}
