import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-di-ld-ui-loading',
  templateUrl: './ui-loading.component.html',
  styleUrls: ['./ui-loading.component.scss'],
})
export class UiLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
