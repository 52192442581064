<ng-container *transloco="let t">
  <p-dialog
    position="bottom"
    [(visible)]="dialogErrorHandlerService.openDialog"
    [modal]="true"
    [showHeader]="false"
  >
    <div>
      <web-di-ld-apply-error
        path="/assets/images/foundationIllustrationGenericError.png"
        title="{{
          t(
            'applyError.' +
              dialogErrorHandlerService.dialogMessageCode +
              '.title'
          )
        }}"
        description="{{
          t(
            'applyError.' +
              dialogErrorHandlerService.dialogMessageCode +
              '.description'
          )
        }}"
      ></web-di-ld-apply-error>
    </div>
    <div class="button-container">
      <web-di-ld-button
        (clickEvt)="dialogErrorHandlerService.closeDialog()"
        label="{{ t('common.ok') }}"
      >
      </web-di-ld-button>
    </div>
  </p-dialog>
</ng-container>
