import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { DialogModule } from 'primeng/dialog';
import { TranslocoRootModule } from '../../core/transloco-root.module';
import { UiModule } from '../components';
import { DialogErrorHandlerService } from './dialog-error-handler.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [DialogErrorComponent],
  imports: [CommonModule, DialogModule, TranslocoRootModule, UiModule],
  exports: [DialogErrorComponent],
  providers: [
    DialogErrorHandlerService,
    { provide: ErrorHandler, useExisting: DialogErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: DialogErrorHandlerService,
      multi: true,
    },
  ],
})
export class ErrorHandlerModule {}
