import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  take,
  tap,
  timeout,
} from 'rxjs/operators';
import { environment } from '@environments/environment';

export interface VerifyResponse {
  code: string;
  message: string;
  data: VerifyData;
}
export interface VerifyData {
  accessToken: string;
  cifNoEncrypt: string;
  language: string;
}

@Injectable()
export class HttpService {
  authorizationCode$ = this.extractParam('authorizationCode');
  productType$ = this.extractParam('productType');
  applicationRef$ = this.extractParam('applicationRef');
  aisInfo$ = this.extractParam('info');
  aisChannel$ = this.extractParam('channel');
  verifyToken: VerifyResponse | null = null;
  acceptLang = 'th-TH';
  mockMsg = '';
  environment = environment;

  get nextApiBaseUrl() {
    if (!environment) {
      return '';
    }
    return environment.nextApiBaseUrl ?? '';
  }

  get dglApiBaseUrl() {
    if (!environment) {
      return '';
    }
    return environment.dglApiBaseUrl ?? '';
  }

  get baseUrl() {
    if (!environment) {
      return '';
    }
    return environment.baseUrl ?? '';
  }

  constructor(public http: HttpClient, private route: ActivatedRoute) {}

  get<R = any>(
    path: string,
    condition: { [params: string]: any } = {},
    nextApi = false
  ) {
    const payload = condition;
    const params = new HttpParams({ fromObject: payload });
    let baseUrl = nextApi ? this.nextApiBaseUrl : this.dglApiBaseUrl;
    if (this.environment.mode === 'local') {
      baseUrl = `${this.baseUrl}/dev`;
    }

    const httpOptions = {
      params,
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache', // instruct the browser not to cache the response.
        'Expires': '0' // 0 ensures the response is considered expired immediately.
      }
    };

    return (
      this.http
        // .get<R>(`${baseUrl}/dev${path}`, { params })
        .get<R>(`${baseUrl}${path}`,  httpOptions)
        .pipe(
          catchError((err) => {
            console.error(err);
            return throwError(err);
          })
        )
    );
  }

  post<R = any>(
    path: string,
    body: any,
    nextApi = false,
    headers?: HttpHeaders
  ) {
    const options = { headers: headers } || {};
    let baseUrl = nextApi ? this.nextApiBaseUrl : this.dglApiBaseUrl;
    if (this.environment.mode === 'local') {
      baseUrl = `${this.baseUrl}/dev`;
    }
    return this.http.post<R>(`${baseUrl}${path}`, body, options).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  postFormData<R = any>(path: string, formData: FormData, nextApi = true) {
    const baseUrl = nextApi ? this.nextApiBaseUrl : this.dglApiBaseUrl;
    return this.http.post<R>(`${baseUrl}${path}`, formData).pipe(
      catchError((err) => {
        console.error(err);
        return EMPTY;
      })
    );
  }

  put<R = any>(path: string, body: any, nextApi = true) {
    const baseUrl = nextApi ? this.nextApiBaseUrl : this.dglApiBaseUrl;
    return this.http.put<R>(`${baseUrl}${path}`, body);
  }

  patch<R = any>(path: string, body: any, nextApi = true) {
    const baseUrl = nextApi ? this.nextApiBaseUrl : this.dglApiBaseUrl;
    return this.http.patch<R>(`${baseUrl}${path}`, body);
  }

  delete<R = any>(path: string, payload?: any, nextApi = true) {
    const baseUrl = nextApi ? this.nextApiBaseUrl : this.dglApiBaseUrl;
    if (!payload) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: payload,
      };
      return this.http.delete<R>(`${baseUrl}${path}`, options);
    } else {
      return this.http.delete<R>(`${baseUrl}${path}`);
    }
  }

  /**
   * เป็น api เส้นแรกที่เรียก เพื่อ verify token
   * @param token token ที่ได้จาก QueryParam
   * @returns Observable จาก verify
   */
  httpVerifyToken() {
    // const header = new HttpHeaders({
    //   authorization: `Bearer ${token}`,
    // });
    let uuid = '';
    this.authorizationCode$.subscribe((authorizationCode) => {
      uuid = authorizationCode;
    });

    let aisInfo;
    this.aisInfo$.subscribe((info) => {
      aisInfo = info;
    });
    console.log('aisInfo: ' + aisInfo);
    let aisChannel;
    this.aisChannel$.subscribe((channel) => {
      aisChannel = channel;
    });
    console.log('aisChannel: ' + aisChannel);

    return this.post<VerifyResponse>(
      `/lfm-access/app/v2/token/verify`,
      {
        authorizationCode: uuid,
      },
      false
    ).pipe(
      tap((verify) => {
        this.acceptLang = verify.data.language || 'th-TH';
        this.verifyToken = verify;
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      })
    );
  }

  /**
   * ดึงข้อมูลจากเส้น verify ถ้าเคยดึงแล้ว ให้ใช้ cache แทน
   * @param token token ที่ได้จาก QueryParam
   * @returns Observable จาก verify
   */
  getVerifyToken() {
    if (this.verifyToken) {
      return of(this.verifyToken);
    }
    return this.httpVerifyToken();
  }

  /**
   * สร้าง object เงื่อนไขใหม่ที่ value ของ properties ไม่ null
   * @param condition เงื่อนไข
   * @returns object ที่ถูก clone ที่ properties ไม่ null
   */
  purifyNotNull(condition: { [params: string]: any }) {
    const payload: { [params: string]: any } = {};
    Object.entries(condition).forEach(([key, value]) => {
      if (value !== null) {
        payload[key] = value;
      }
    });
    return payload;
  }

  /**
   * รอค่าจาก Query
   * @param param ชื่อ Parameter
   * @param time เวลา timeout ที่จะรอ QueryParameter
   * @returns
   */
  private extractParam(param: string, time = 400) {
    return this.route.queryParams.pipe(
      map((params) => params[param]),
      filter((param) => Boolean(param)),
      timeout(time),
      catchError((e) => {
        console.error(e);
        return EMPTY;
      }),
      take(1),
      shareReplay(1)
    );
  }

  public clearProductType() {
    this.productType$ = of();
  }
}
