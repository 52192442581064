import { Injectable } from '@angular/core';
import { HttpService } from '@web-di-ld/apps/http';
import { AppStateService } from '../../../app-state.service';
import { ILoanOffers, IProductDetail, IProductShelf } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class LoanProductService {
  public errorCode: string = '';

  constructor(
    private appState: AppStateService,
    private httpService: HttpService
  ) {}

  getLoans() {
    return this.httpService
      .get<IProductShelf>('/lfm-offers/app/v1/offers')
      .toPromise()
      .then((response: any) => {
        this.errorCode = '';
        return response;
      })
      .catch((error) => {
        this.errorCode = error.error.code;
        console.error(error);
        return [];
      });
  }

  public updateLoanOffers(loanOffers: ILoanOffers) {
    this.appState.updateStateLoanOffers(loanOffers);
  }

  public updateLoanProduct(loanProduct: IProductShelf) {
    this.appState.updateStateLoanProduct(loanProduct);
  }

  public clearProductDetail() {
    const emptyProductDetail = ({} as any) as IProductDetail;
    this.appState.updateStateProductDetail(emptyProductDetail);
  }
}
