import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FlowService } from './flow-service.service';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private _items = [
    {
      label: 'test',
      styleClass: 'first step-done',
      flow: '',
    },
    {
      label: 'test2',
      styleClass: 'second',
      flow: '',
    },
  ];
  public get items() {
    return this._items;
  }
  public set items(value) {
    this._items = value;
  }
  activeIndex = 0;
  showStep: boolean = true;

  constructor() {}

  clear() {
    this.showStep = false;
    this.items = [];
  }
}
