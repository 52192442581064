export interface Province {
  provinceName: string;
  provinceCode: string;
}

export interface District {
  districtName: string;
  districtCode: string;
}

export interface SubDistrict {
  subdistrictName: string;
  subdistrictCode: string;
  postcode: string;
}

export interface BusinessInformation {
  officeAddress1: string;
  officeAddress1UpdFlag: string;
  officeAddress2: string;
  officeAddress2UpdFlag: string;
  officeAddress3: string;
  officeAddress3UpdFlag: string;
  officeAddress4: string;
  officeAddress4UpdFlag: string;
  officeSubDistrictCode: string;
  officeSubDistrictName: string;
  officeSubDistrictUpdFlag: string;
  officeDistrictCode: string;
  officeDistrictName: string;
  officeDistrictUpdFlag: string;
  officeProvinceCode: string;
  officeProvinceName: string;
  officeProvinceUpdFlag: string;
  officePostalCode: string;
  officePostalCodeUpdFlag: string;
  officeCountry: string;
  officeCountryUpdFlag?: string;
  officeName: string;
  officeNameUpdFlag: string;
}
