import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[ktbLoadImage]',
})
export class LoadImageDirective {
  @Input('ktbLoadImageOf') urlImage: string | SafeUrl | undefined | null;

  viewRef?: EmbeddedViewRef<any>;
  urlAction = new ReplaySubject<string>(1);
  private destroy$ = new Subject<void>();
  safeUrl$ = this.urlAction.pipe(
    switchMap((url) => {
      if (url) {
        return this.loadImage$(url).pipe(
          catchError((err) => {
            this.destroy();
            this.createErrorView();
            return EMPTY;
          })
        );
      }
      // undefined or null url
      this.createErrorView();
      return EMPTY;
    })
  );

  constructor(
    private tpr: TemplateRef<any>,
    public vc: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.safeUrl$.pipe(takeUntil(this.destroy$)).subscribe((safeUrl) => {
      this.destroy();
      this.createCompleteView(safeUrl);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.urlImage) {
      this.urlAction.next(changes.urlImage.currentValue);
    }
  }

  destroy() {
    if (this.viewRef) {
      this.viewRef.destroy();
    }
  }

  createErrorView() {
    this.viewRef = this.vc.createEmbeddedView(this.tpr, {
      $implicit: null,
      complete: false,
      error: true,
    });
    this.cd.detectChanges();
  }

  createCompleteView(safeUrl: SafeUrl) {
    this.viewRef = this.vc.createEmbeddedView(this.tpr, {
      $implicit: safeUrl,
      complete: true,
      error: false,
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadImage$(url: string) {
    return new Observable<string>((observer) => {
      const image = new Image();
      image.onload = () => {
        observer.next(url);
        observer.complete();
      };
      image.onerror = (err) => observer.error(err);
      image.src = url;
    });
  }
}
