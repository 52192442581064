import {
  Component,
  ViewEncapsulation,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { IPromotionBanner } from 'apps/di-ld/src/app/core/interfaces';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
dayjs.extend(isBetween);
SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'web-di-ld-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PromoComponent implements OnInit {
  @Input() items!: Array<IPromotionBanner>;
  @Input() autoSwipeTime!: number;
  @Output() onClick = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  public onClickBanner(item: IPromotionBanner, index: number) {
    item.order = index + 1;
    this.onClick.emit(item);
  }

  public isShowTag(item: IPromotionBanner) {
    if (item && item.tagImgURL && item.tagStartDate) {
      const isBetweenDate = dayjs().isBetween(
        item.tagStartDate,
        dayjs(item.tagEndDate)
      );
      return isBetweenDate;
    }
    return false;
  }
}
