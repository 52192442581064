export interface EducationLevel {
  educationCode: string;
  educationDescription: string;
}
export interface EducationLevelLang {
  th: EducationLevel[];
  en: EducationLevel[];
}
export interface CurrentResidence {
  currentResidenceCode: string;
  currentResidenceDescription: string;
}
export interface CurrentResidenceLang {
  th: CurrentResidence[];
  en: CurrentResidence[];
}
export interface MaritalStatus {
  maritalStatus: string;
  maritalDescription: string;
}
export interface MaritalStatusLang {
  th: MaritalStatus[];
  en: MaritalStatus[];
}

export interface CurrentResidenceDuration {
  year: number;
  month: number;
}
export interface AdditionalInformation {
  educationCode: string;
  educationLevelUpdFlag?: string;
  educationDescription: string;
  currentResidenceCode: string;
  currentResidenceUpdFlag: string;
  currentResidenceDescription: string;
  currentResidenceDuration: CurrentResidenceDuration;
  periodOfResidenceYear?: number;
  periodOfResidenceMonth?: number;
  maritalStatus: string;
  maritalStatusUpdFlag?: string;
  maritalDescription: string;
  haveChildren?: boolean;
  numberOfChildren: string;
  thaiPoliticalRelateFlag: string;
  thaiPoliticianRelateFlagUpdFlag?: string;
  interPoliticianRelateFlag: string;
  interPoliticianRelateFlagUpdFlag?: string;
  approvedLoanMoreTwo: any;
  occupationGroupCode: string;
  occupationGroup: string;
  subOccupationCode?: string;
  subOccupation?: string;
  isOccupationOther?: boolean;
  subOccupationFreeText?: string;
  yearsOfWorkingPeriod?: number;
  monthsOfWorkingPeriod?: number;
}
export interface PersonalInformation {
  title: string;
  fullname: string;
  dateOfBirth: string;
  nationalID: string;
  mobileNumber: string;
  email: string;
}
export interface CDDInformation {
  internationalOrganization: string;
  foreignCountry: string;
  [key: string]: any;
}
