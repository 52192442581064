import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
@Component({
  selector: 'web-di-ld-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() label = 'Next';
  @Input() width = '100%';
  @Input() height = '48px';
  @Input() disabled = false;
  @Output() clickEvt = new EventEmitter();

  constructor() {}

  onClick() {
    if (!this.disabled) {
      this.clickEvt.emit();
    }
  }
}
