import { Component } from '@angular/core';

@Component({
  selector: 'web-di-ld-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  constructor() {}
}
