import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';
import {
  AdditionalInformation,
  Application,
  BusinessInformation,
  CDDInformation,
  Consent,
  CustomerDetail,
  IFlow,
  ILoanOffers,
  IProductDetail,
  IProductShelf,
  IPromotionBanner,
  ITemplate,
  LoanApplication,
  PersonalInformation,
  TopupLoanApplication,
} from './core/interfaces';
import { Router } from '@angular/router';
import {
  IAdditionalInfo,
  IBusinessInfo,
  IDraft,
  ILoanInfo,
  IMailingAddress,
  ITopupLoanInfo,
} from './core/interfaces/draft.interface';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private readonly applicationState = new BehaviorSubject<Application>(
    {} as Application
  );
  readonly applicationState$ = this.applicationState.pipe(
    distinctUntilChanged(),
    tap((state) => Object.freeze(state)),
    shareReplay(1)
  );

  private snapshotLoanApplication!: LoanApplication | TopupLoanApplication;
  readonly loanApplication$ = this.applicationState$.pipe(
    this.select(
      (state) => state.loanApplication,
      (loanApplicationState) =>
        (this.snapshotLoanApplication = loanApplicationState)
    )
  );

  private snapshotContentState!: Consent;
  readonly consent$ = this.applicationState$.pipe(
    this.select(
      (state) => state.consent,
      (contentState) => (this.snapshotContentState = contentState)
    )
  );
  private snapshotPersonalInformation!: PersonalInformation;
  readonly personalInformation$ = this.applicationState$.pipe(
    this.select(
      (state) => state.personalInformation,
      (personalInformationState) =>
        (this.snapshotPersonalInformation = personalInformationState)
    )
  );
  private snapshotBusinessInformation!: BusinessInformation;
  readonly businessInformation$ = this.applicationState$.pipe(
    this.select(
      (state) => state.businessInformation,
      (businessInformationState) =>
        (this.snapshotBusinessInformation = businessInformationState)
    )
  );
  private snapshotAdditionalInformation!: AdditionalInformation;
  readonly additionalInformation$ = this.applicationState$.pipe(
    this.select(
      (state) => state.additionalInformation,
      (additionalInformationState) =>
        (this.snapshotAdditionalInformation = additionalInformationState)
    )
  );

  private snapshotCDDInformation!: CDDInformation;
  readonly CDDInformation$ = this.applicationState$.pipe(
    this.select(
      (state) => state.CDDInformation,
      (CDDInformationState) =>
        (this.snapshotCDDInformation = CDDInformationState)
    )
  );

  private snapshotProductDetail!: IProductDetail;
  readonly ProductDetail$ = this.applicationState$.pipe(
    this.select(
      (state) => state.productDetail,
      (ProductDetail) => (this.snapshotProductDetail = ProductDetail)
    )
  );

  private snapshotCustomerDetail!: CustomerDetail;
  readonly CustomerDetail$ = this.applicationState$.pipe(
    this.select(
      (state) => state.customerDetail,
      (CustomerDetail) => (this.snapshotCustomerDetail = CustomerDetail)
    )
  );

  private snapshotLoanOffers!: ILoanOffers;
  readonly LoanOffers$ = this.applicationState$.pipe(
    this.select(
      (state) => state.loanOffers,
      (loanOffers) => (this.snapshotLoanOffers = loanOffers)
    )
  );

  private snapshotLoanProduct!: IProductShelf;
  readonly LoanProduct$ = this.applicationState$.pipe(
    this.select(
      (state) => state.loanProduct,
      (loanProduct) => (this.snapshotLoanProduct = loanProduct)
    )
  );

  private snapshotFlow!: IFlow;
  readonly Flow$ = this.applicationState$.pipe(
    this.select(
      (state) => state.flow,
      (flow) => (this.snapshotFlow = flow)
    )
  );

  private snapshotLoanInfoDraft?: ILoanInfo;
  readonly loanInfoDraft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft?.loanInfo,
      (loanInfoState) => (this.snapshotLoanInfoDraft = loanInfoState)
    )
  );

  private snapshotBusinessInfoDraft?: IBusinessInfo;
  readonly businessInfoDraft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft?.businessInfo,
      (businessInformationState) =>
        (this.snapshotBusinessInfoDraft = businessInformationState)
    )
  );

  private snapshotAddtionalInformationDraft?: IAdditionalInfo;
  readonly additionalInformationDraft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft?.additionalInfo,
      (additionalInformationState) =>
        (this.snapshotAddtionalInformationDraft = additionalInformationState)
    )
  );

  /* Consent */
  private snapshotMarketingConsentDraft?: boolean;
  readonly marketingConsentDraft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft?.marketingConsent,
      (contentState) => (this.snapshotMarketingConsentDraft = contentState)
    )
  );

  private snapshotNcbConsentDraft?: boolean;
  readonly ncbConsentDraft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft?.ncbConsent,
      (contentState) => (this.snapshotNcbConsentDraft = contentState)
    )
  );

  private snapshotOcpbConsentDraft?: boolean;
  readonly ocpbConsentDraft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft?.ocpbConsent,
      (contentState) => (this.snapshotOcpbConsentDraft = contentState)
    )
  );

  private snapshotDraft!: IDraft;
  readonly draft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft,
      (draft) => (this.snapshotDraft = draft)
    )
  );

  private snapshotPromotion!: ITemplate;
  readonly promotion$ = this.applicationState$.pipe(
    this.select(
      (state) => state.promotion,
      (promotion) => (this.snapshotPromotion = promotion)
    )
  );
  private snapshotPromotionBanner!: IPromotionBanner;
  readonly promotionBanner$ = this.applicationState$.pipe(
    this.select(
      (state) => state.promotionBanner,
      (promotionBannerState) =>
        (this.snapshotPromotionBanner = promotionBannerState)
    )
  );

  private snapshotMailingAddress!: IMailingAddress;
  readonly maillingAddr$ = this.applicationState$.pipe(
    this.select(
      (state) => state.mailingAddress,
      (mailingAddressState) =>
        (this.snapshotMailingAddress = mailingAddressState)
    )
  );

  private snapshotMailingAddressDraft?: boolean;
  readonly mailingAddressDraft$ = this.applicationState$.pipe(
    this.select(
      (state) => state.draft?.ncbConsent,
      (contentState) => (this.snapshotMailingAddressDraft = contentState)
    )
  );

  private snapshotSmartPromo!: IPromotionBanner;
  readonly smartPromo$ = this.applicationState$.pipe(
    this.select(
      (state) => state.smartPromo,
      (smartPromoState) => (this.snapshotSmartPromo = smartPromoState)
    )
  );

  get snapshot() {
    return {
      application: this.applicationState.value,
      loanApplication: this.snapshotLoanApplication,
      personalInformation: this.snapshotPersonalInformation,
      businessInformation: this.snapshotBusinessInformation,
      additionalInformation: this.snapshotAdditionalInformation,
      CDDInformation: this.snapshotCDDInformation,
      consent: this.snapshotContentState,
      productDetail: this.snapshotProductDetail,
      customerDetail: this.snapshotCustomerDetail,
      loanOffers: this.snapshotLoanOffers,
      loanProduct: this.snapshotLoanProduct,
      flow: this.snapshotFlow,
      draft: this.snapshotDraft,
      applicationInfoDraft: this.snapshotLoanInfoDraft,
      businessInfoDraft: this.snapshotBusinessInfoDraft,
      marketingConsentDraft: this.snapshotMarketingConsentDraft,
      ncbConsentDraft: this.snapshotNcbConsentDraft,
      ocpbConsentDraft: this.snapshotOcpbConsentDraft,
      additionalInfoDraft: this.snapshotAddtionalInformationDraft,
      promotion: this.snapshotPromotion,
      promotionBanner: this.snapshotPromotionBanner,
      smartPromo: this.snapshotSmartPromo,
      mailingAddress: this.snapshotMailingAddress,
    };
  }

  private readonly subscription = this.makeSubscription(
    this.applicationState$,
    this.loanApplication$,
    this.personalInformation$,
    this.businessInformation$,
    this.additionalInformation$,
    this.CDDInformation$,
    this.ProductDetail$,
    this.CustomerDetail$,
    this.LoanOffers$,
    this.LoanProduct$,
    this.Flow$,
    this.draft$,
    this.loanInfoDraft$,
    this.businessInfoDraft$,
    this.additionalInformationDraft$,
    this.marketingConsentDraft$,
    this.ncbConsentDraft$,
    this.ocpbConsentDraft$,
    this.promotion$,
    this.promotionBanner$,
    this.smartPromo$,
    this.maillingAddr$,
    this.mailingAddressDraft$
  );

  previousUrl = '';
  currentUrl = this.location.path();

  constructor(private location: Location, private router: Router) {
    this.location.onUrlChange((url) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = url;
    });
  }

  /**
   * internal only
   */
  private reduceState(fn: (prevState: Application) => Application) {
    const prevState = this.applicationState.value;
    const currState = fn(prevState);
    this.applicationState.next({ ...currState });
  }

  /**
   * internal only
   * @param selector
   * @param effect
   * @returns
   */
  private select<T, R>(
    selector: (state: T) => R,
    effect?: (selectState: R) => void
  ) {
    return (source: Observable<T>) =>
      source.pipe(
        map((state) => selector(state)),
        tap((state) => Object.freeze(state)),
        filter((selectState) => Boolean(selectState)),
        distinctUntilChanged(),
        tap((selectState) => effect && effect(selectState)),
        shareReplay(1)
      );
  }
  /**
   * internal only
   * @param srcs
   * @returns
   */
  private makeSubscription(...srcs: Observable<any>[]) {
    return merge(...srcs).subscribe();
  }

  /**
   * disconnect all state stream
   */
  disconnect() {
    this.subscription.unsubscribe();
  }

  updateStateLoanApplication(
    loanApplication: LoanApplication | TopupLoanApplication
  ) {
    const loanInfo: ILoanInfo | ITopupLoanInfo = {
      referenceKey: this.snapshotLoanInfoDraft?.referenceKey || '',
      productCode: this.snapshotProductDetail.productCode || '',
      occupationSector: loanApplication.occupation?.occupationSector,
      monthlyIncome: Number(loanApplication.salary),
      loanAmount: Number(loanApplication.loanAmount),
      selectedPaymentTerm: Number(loanApplication.paymentTerm),
      confirmation: loanApplication.confirm[0] == 'true',
      referralCode: loanApplication.referralCode,
      uploadDocumentFlag: loanApplication.uploadPayslip,
      isCheckAcknowledgement: loanApplication.isCheckAcknowledgement || false,
    };

    this.updateStateLoanInfoDraft(loanInfo);
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        loanApplication: { ...loanApplication },
      };
      return newState;
    });
  }

  updateStatePersonalInformation(personalInformation: PersonalInformation) {
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        personalInformation: { ...personalInformation },
      };
      return newState;
    });
  }

  updateStateBusinessInformation(businessInformation: BusinessInformation) {
    const businessInfo: IBusinessInfo = {
      businessName: businessInformation.officeName || '',
      businessNameUpdFlag: businessInformation.officeNameUpdFlag == 'true',
      businessAddress: {
        address1: businessInformation.officeAddress1 || '',
        address1UpdFlag: businessInformation.officeAddress1UpdFlag == 'true',
        address2: businessInformation.officeAddress2 || '',
        address2UpdFlag: businessInformation.officeAddress2UpdFlag == 'true',
        address3: businessInformation.officeAddress3 || '',
        address3UpdFlag: businessInformation.officeAddress3UpdFlag == 'true',
        address4: businessInformation.officeAddress4 || '',
        address4UpdFlag: businessInformation.officeAddress4UpdFlag == 'true',
        provinceCode: businessInformation.officeProvinceCode || '',
        provinceName: businessInformation.officeProvinceName || '',
        provinceUpdFlag: businessInformation.officeProvinceUpdFlag == 'true',
        districtCode: businessInformation.officeDistrictCode || '',
        districtName: businessInformation.officeDistrictName || '',
        districtUpdFlag: businessInformation.officeDistrictUpdFlag == 'true',
        subdistrictCode: businessInformation.officeSubDistrictCode || '',
        subdistrictName: businessInformation.officeSubDistrictName || '',
        subdistrictUpdFlag:
          businessInformation.officeSubDistrictUpdFlag == 'true',
        postCode: businessInformation.officePostalCode || '',
        postCodeUpdFlag: businessInformation.officePostalCodeUpdFlag == 'true',
      },
    };

    this.updateStateBusinessInformationDraft(businessInfo);
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        businessInformation: { ...businessInformation },
      };
      return newState;
    });
  }

  updateStateAdditionalInformation(
    additionalInformation: AdditionalInformation
  ) {
    this.saveToAdditionalDraft(additionalInformation);
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        additionalInformation: { ...additionalInformation },
      };
      return newState;
    });
  }

  updateStateCDDInformation(CDDInformation: CDDInformation) {
    this.saveToAdditionalDraft(CDDInformation);
    this.reduceState((prevState) => {
      const newState = { ...prevState, CDDInformation: { ...CDDInformation } };
      return newState;
    });
  }

  updateStateConsent(consent: Consent) {
    this.saveToCensentDraft(consent);
    this.reduceState((prevState) => {
      const newState = { ...prevState, consent: { ...consent } };
      return newState;
    });
  }

  updateStateProductDetail(productDetail: IProductDetail) {
    this.reduceState((prevState) => {
      const newState = { ...prevState, productDetail: { ...productDetail } };
      return newState;
    });
  }

  updateStatePDFUrlOfProductDetail(saleSheetUrl: string) {
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        productDetail: { ...prevState.productDetail, saleSheetUrl },
      };
      return newState;
    });
  }

  updateStateCustomerDetail(customerDetail: CustomerDetail) {
    this.reduceState((prevState) => {
      const newState = { ...prevState, customerDetail: { ...customerDetail } };
      return newState;
    });
  }

  updateStateLoanOffers(loanOffers: ILoanOffers) {
    this.reduceState((prevState) => {
      const newState = { ...prevState, loanOffers: { ...loanOffers } };
      return newState;
    });
  }

  updateStateLoanProduct(loanProduct: IProductShelf) {
    this.reduceState((prevState) => {
      const newState = { ...prevState, loanProduct: { ...loanProduct } };
      return newState;
    });
  }

  updateStateMailingAddress(mailingAddress: IMailingAddress) {
    this.reduceState((prevState) => {
      const newState = { ...prevState, mailingAddress: { ...mailingAddress } };
      return newState;
    });
  }

  updateStateFlow(flow: IFlow) {
    this.reduceState((prevState) => {
      if (!flow.flow) {
        flow.flow = prevState.flow?.flow || undefined;
      }
      if (!flow.enterFlow) {
        flow.enterFlow = prevState.flow?.enterFlow || undefined;
      }
      if (!flow.state) {
        flow.state = prevState.flow?.state || undefined;
      }
      if (flow.isTopup === undefined) {
        flow.isTopup = prevState.flow?.isTopup || undefined;
      }
      const newState = { ...prevState, flow: { ...flow } };
      return newState;
    });
  }

  updateStateLoanInfoDraft(loanApplication: ILoanInfo) {
    this.reduceState((prevState) => {
      if (prevState.draft) {
        const newState = {
          ...prevState,
          draft: { ...prevState.draft, loanInfo: loanApplication },
        };
        return newState;
      }
      return prevState;
    });
  }

  updateStateBusinessInformationDraft(businessInformation: IBusinessInfo) {
    this.reduceState((prevState) => {
      if (prevState.draft) {
        const newState = {
          ...prevState,
          draft: { ...prevState.draft, businessInfo: businessInformation },
        };
        return newState;
      }
      return prevState;
    });
  }

  updateStateAdditionalInformationDraft(
    additionalInformation: IAdditionalInfo
  ) {
    this.reduceState((prevState) => {
      if (prevState.draft) {
        const newState = {
          ...prevState,
          draft: { ...prevState.draft, additionalInfo: additionalInformation },
        };
        return newState;
      }
      return prevState;
    });
  }

  updateStateMarketingConsentDraft(marketingConsent: boolean) {
    this.reduceState((prevState) => {
      if (prevState.draft) {
        const newState = {
          ...prevState,
          draft: { ...prevState.draft, marketingConsent: marketingConsent },
        };
        return newState;
      }
      return prevState;
    });
  }

  updateStateOcpbConsentDraft(ocpbConsent: boolean) {
    this.reduceState((prevState) => {
      if (prevState.draft) {
        const newState = {
          ...prevState,
          draft: { ...prevState.draft, ocpbConsent: ocpbConsent },
        };
        return newState;
      }
      return prevState;
    });
  }

  updateStateNcbConsentDraft(ncbConsent: boolean) {
    this.reduceState((prevState) => {
      if (prevState.draft) {
        const newState = {
          ...prevState,
          draft: { ...prevState.draft, ncbConsent: ncbConsent },
        };
        return newState;
      }
      return prevState;
    });
  }

  updateStateDraft(draft: IDraft) {
    this.reduceState((prevState) => {
      const newState = { ...prevState, draft: { ...draft } };
      this.updateDraft(draft);
      return newState;
    });
  }

  updateStatePromotion(promotion: ITemplate) {
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        promotion: { ...promotion },
      };
      return newState;
    });
  }

  updateStateMailingAddressDraft(mailingAddress: IMailingAddress) {
    this.reduceState((prevState) => {
      if (prevState.draft) {
        const newState = {
          ...prevState,
          draft: { ...prevState.draft, mailingAddress: mailingAddress },
        };
        return newState;
      }
      return prevState;
    });
  }

  updateDraft(draft: IDraft) {
    const loanApplication: LoanApplication | TopupLoanApplication = {
      productName: '',
      loanAmount: draft.loanInfo?.loanAmount
        ? String(draft.loanInfo?.loanAmount)
        : '',
      interestRate: '',
      estimatesMonthlyInstallment: '',
      paymentTerm: draft.loanInfo?.selectedPaymentTerm
        ? String(draft.loanInfo?.selectedPaymentTerm)
        : '',
      salary: draft.loanInfo?.monthlyIncome
        ? String(draft.loanInfo?.monthlyIncome)
        : '',
      confirm: draft.loanInfo?.confirmation
        ? new Array(String(draft.loanInfo?.confirmation))
        : new Array('false'),
      uploadPayslip: draft.loanInfo?.uploadDocumentFlag,
      occupation: {
        occupationSector: draft.loanInfo?.occupationSector || '',
        occupationSectorDescription: '',
      },
      isCheckAcknowledgement: draft.loanInfo?.isCheckAcknowledgement || false,
    };
    const business: BusinessInformation = {
      officeAddress1: draft.businessInfo?.businessAddress?.address1 || '',
      officeAddress1UpdFlag: draft.businessInfo?.businessAddress
        ?.address1UpdFlag
        ? String(draft.businessInfo?.businessAddress?.address1UpdFlag)
        : 'false',
      officeAddress2: draft.businessInfo?.businessAddress?.address2 || '',
      officeAddress2UpdFlag: draft.businessInfo?.businessAddress
        ?.address2UpdFlag
        ? String(draft.businessInfo?.businessAddress?.address2UpdFlag)
        : 'false',
      officeAddress3: draft.businessInfo?.businessAddress?.address3 || '',
      officeAddress3UpdFlag: draft.businessInfo?.businessAddress
        ?.address3UpdFlag
        ? String(draft.businessInfo?.businessAddress?.address3UpdFlag)
        : 'false',
      officeAddress4: draft.businessInfo?.businessAddress?.address4 || '',
      officeAddress4UpdFlag: draft.businessInfo?.businessAddress
        ?.address4UpdFlag
        ? String(draft.businessInfo?.businessAddress?.address4UpdFlag)
        : 'false',
      officeSubDistrictCode:
        draft.businessInfo?.businessAddress?.subdistrictCode || '',
      officeSubDistrictName:
        draft.businessInfo?.businessAddress?.subdistrictName || '',
      officeSubDistrictUpdFlag: draft.businessInfo?.businessAddress
        ?.subdistrictUpdFlag
        ? String(draft.businessInfo?.businessAddress?.subdistrictUpdFlag)
        : 'false',
      officeDistrictCode:
        draft.businessInfo?.businessAddress?.districtCode || '',
      officeDistrictName:
        draft.businessInfo?.businessAddress?.districtName || '',
      officeDistrictUpdFlag: draft.businessInfo?.businessAddress
        ?.districtUpdFlag
        ? String(draft.businessInfo?.businessAddress?.districtUpdFlag)
        : 'false',
      officeProvinceCode:
        draft.businessInfo?.businessAddress?.provinceCode || '',
      officeProvinceName:
        draft.businessInfo?.businessAddress?.provinceName || '',
      officeProvinceUpdFlag: draft.businessInfo?.businessAddress
        ?.provinceUpdFlag
        ? String(draft.businessInfo?.businessAddress?.provinceUpdFlag)
        : 'false',
      officePostalCode: draft.businessInfo?.businessAddress?.postCode || '',
      officePostalCodeUpdFlag: draft.businessInfo?.businessAddress
        ?.postCodeUpdFlag
        ? String(draft.businessInfo?.businessAddress?.postCodeUpdFlag)
        : 'false',
      officeCountry: '',
      officeName: draft.businessInfo?.businessName || '',
      officeNameUpdFlag: draft.businessInfo?.businessNameUpdFlag
        ? String(draft.businessInfo?.businessNameUpdFlag)
        : 'false',
    };

    const additional: AdditionalInformation = {
      educationCode: draft.additionalInfo?.educationCode || '',
      educationLevelUpdFlag: draft.additionalInfo?.educationLevelUpdFlag
        ? String(draft.additionalInfo?.educationLevelUpdFlag)
        : '',
      educationDescription: '',
      currentResidenceCode:
        draft.additionalInfo?.residence?.currentResidenceCode || '',
      currentResidenceUpdFlag: '',
      currentResidenceDescription: '',
      currentResidenceDuration: {
        year: draft.additionalInfo?.residence?.periodYear || 0,
        month: draft.additionalInfo?.residence?.periodMonth || 0,
      },
      periodOfResidenceYear:
        draft.additionalInfo?.residence?.periodYear != undefined &&
        draft.additionalInfo?.residence?.periodYear != null
          ? draft.additionalInfo?.residence?.periodYear
          : undefined,
      periodOfResidenceMonth:
        draft.additionalInfo?.residence?.periodMonth != undefined &&
        draft.additionalInfo?.residence?.periodMonth != null
          ? draft.additionalInfo?.residence?.periodMonth
          : undefined,
      maritalStatus: draft.additionalInfo?.maritalStatus || '',
      maritalStatusUpdFlag: draft.additionalInfo?.maritalStatusUpdFlag
        ? String(draft.additionalInfo?.maritalStatusUpdFlag)
        : '',
      maritalDescription: '',
      haveChildren:
        draft.additionalInfo?.children?.haveChildren == true ||
        draft.additionalInfo?.children?.haveChildren == false
          ? draft.additionalInfo?.children.haveChildren
          : undefined,
      numberOfChildren: String(
        draft.additionalInfo?.children?.numberOfChildren || '0'
      ),
      thaiPoliticalRelateFlag: '',
      thaiPoliticianRelateFlagUpdFlag: '',
      interPoliticianRelateFlag: '',
      interPoliticianRelateFlagUpdFlag: '',
      approvedLoanMoreTwo: draft.additionalInfo?.twoMonthExistingLoan,
      occupationGroupCode: draft.additionalInfo?.occupationGroupCode || '',
      occupationGroup: draft.additionalInfo?.occupationGroupCode || '',
      subOccupationCode: draft.additionalInfo?.subOccupationCode || '',
      subOccupationFreeText: draft.additionalInfo?.subOccupationFreeText || '',
      yearsOfWorkingPeriod: draft.additionalInfo?.yearsOfWorkingPeriod,
      monthsOfWorkingPeriod: draft.additionalInfo?.monthsOfWorkingPeriod,
    };

    const consent: Consent = {
      marketingConsent: draft.marketingConsent,
      ncbConsent: draft.ncbConsent,
      ocpbConsent: draft.ocpbConsent,
      applicationRef: '',
      version: '',
    };

    const mailingAddress: IMailingAddress = {
      address1: draft.mailingAddress?.address1 ?? '',
      address1UpdFlag: draft.mailingAddress?.address1UpdFlag ?? false,
      address2: draft.mailingAddress?.address2 ?? '',
      address2UpdFlag:
        draft.businessInfo?.businessAddress?.address2UpdFlag ?? false,
      address3: draft.mailingAddress?.address3 ?? '',
      address3UpdFlag:
        draft.businessInfo?.businessAddress?.address3UpdFlag ?? false,
      address4: draft.mailingAddress?.address4 ?? '',
      address4UpdFlag:
        draft.businessInfo?.businessAddress?.address4UpdFlag ?? false,
      subdistrictCode:
        draft.businessInfo?.businessAddress?.subdistrictCode ?? '',
      subdistrictName:
        draft.businessInfo?.businessAddress?.subdistrictName ?? '',
      subdistrictUpdFlag:
        draft.businessInfo?.businessAddress?.subdistrictUpdFlag ?? false,
      districtCode: draft.businessInfo?.businessAddress?.districtCode ?? '',
      districtName: draft.businessInfo?.businessAddress?.districtName ?? '',
      districtUpdFlag:
        draft.businessInfo?.businessAddress?.districtUpdFlag ?? false,
      provinceCode: draft.businessInfo?.businessAddress?.provinceCode ?? '',
      provinceName: draft.businessInfo?.businessAddress?.provinceName ?? '',
      provinceUpdFlag:
        draft.businessInfo?.businessAddress?.provinceUpdFlag ?? false,
      postCode: draft.businessInfo?.businessAddress?.postCode ?? '',
      postCodeUpdFlag:
        draft.businessInfo?.businessAddress?.postCodeUpdFlag ?? false,
      country: draft.mailingAddress?.country ?? '',
      countryUpdFlag: draft.mailingAddress?.countryUpdFlag ?? false,
    };

    const refKey: ILoanInfo = { referenceKey: draft.loanInfo?.referenceKey };
    this.updateStateLoanApplication(loanApplication);
    this.updateStateBusinessInformation(business);
    this.updateStateAdditionalInformation(additional);
    this.updateStateConsent(consent);
    this.updateStateMailingAddress(mailingAddress);
  }

  // CONVERT OLD TO NEW
  saveToAdditionalDraft(info: AdditionalInformation | CDDInformation) {
    const addtional: IAdditionalInfo = {
      educationCode:
        info.educationCode || this.snapshotAdditionalInformation?.educationCode,
      educationLevelUpdFlag:
        info.educationLevelUpdFlag == 'true' ||
        this.snapshotAdditionalInformation?.educationLevelUpdFlag == 'true',
      residence: {
        currentResidenceCode:
          info.currentResidenceCode ||
          this.snapshotAdditionalInformation?.currentResidenceCode,
        periodYear: info.periodOfResidenceYear,
        periodMonth: info.periodOfResidenceMonth,
      },
      maritalStatus:
        info.maritalStatus || this.snapshotAdditionalInformation?.maritalStatus,
      maritalStatusUpdFlag:
        info.maritalStatusUpdFlag == 'true' ||
        this.snapshotAdditionalInformation?.maritalStatus == 'true',
      children: {
        haveChildren: info.haveChildren,
        numberOfChildren: Number(info.numberOfChildren),
      },
      twoMonthExistingLoan:
        info.approvedLoanMoreTwo != null &&
        info.approvedLoanMoreTwo !== undefined
          ? Boolean(info.approvedLoanMoreTwo) === true
          : this.snapshotAdditionalInformation != null &&
            this.snapshotAdditionalInformation.approvedLoanMoreTwo != null &&
            this.snapshotAdditionalInformation.approvedLoanMoreTwo !== undefined
          ? Boolean(this.snapshotAdditionalInformation?.approvedLoanMoreTwo) ===
            true
          : undefined,
      occupationGroupCode: info.occupationGroupCode,
      subOccupationCode: info.subOccupationCode,
      yearsOfWorkingPeriod: info.yearsOfWorkingPeriod,
      monthsOfWorkingPeriod: info.monthsOfWorkingPeriod,
    };
    this.updateStateAdditionalInformationDraft(addtional);
  }

  saveToCensentDraft(consent: Consent) {
    this.updateStateMarketingConsentDraft(
      consent.marketingConsent == 'true' ||
        this.snapshotContentState?.marketingConsent == 'true' ||
        false
    );
    this.updateStateNcbConsentDraft(
      consent.ncbConsent == 'true' ||
        this.snapshotContentState?.ncbConsent == 'true' ||
        false
    );

    this.updateStateOcpbConsentDraft(
      consent.ocpbConsent == 'true' ||
        this.snapshotContentState?.ocpbConsent == 'true' ||
        false
    );
  }

  updateStatePromotionBanner(promotion: IPromotionBanner) {
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        promotionBanner: { ...promotion },
      };
      return newState;
    });
  }

  updateStateSmartPromo(promo: IPromotionBanner) {
    this.reduceState((prevState) => {
      const newState = {
        ...prevState,
        smartPromo: { ...promo },
      };
      return newState;
    });
  }

  clearState() {
    this.applicationState.next({} as Application);
  }
}
