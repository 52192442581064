<ng-container *transloco="let t">
  <div class="error-container" *ngIf="dialogService.displayError">
    <div class="p-d-flex title-wrapper">
      <img
        *ngIf="enableBackButton"
        src="/assets/images/arrowBack@3x.png"
        class="arrow-back"
        (click)="emitClickOnBack()"
      />
      <div class="title" *ngIf="title">{{ title }}</div>
    </div>
    <web-di-ld-apply-error
      [path]="path"
      title="{{ t('applyError.' + case + '.title', { message: moreTitle }) }}"
      description="{{
        t('applyError.' + case + '.description', {
          message: moreMessage,
          maxSubmission: maxSubmission,
          cutOffDay: cutOffDay
        })
      }}"
    ></web-di-ld-apply-error>
    <web-di-ld-button
      [label]="buttonTitle ? buttonTitle : t('common.ok')"
      (click)="emitClickEvt()"
    ></web-di-ld-button>
  </div>
</ng-container>
