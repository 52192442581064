import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  Province,
  District,
  SubDistrict,
  BusinessInformation,
  IMailingAddress,
} from '../../interfaces';
import { environment } from '../../../../environments/environment';
import { AppStateService } from '../../../app-state.service';
import { HttpService } from '@web-di-ld/apps/http';

// service
@Injectable({
  providedIn: 'root',
})
export class EditInformationService {
  constructor(
    private httpClient: HttpClient,
    private appState: AppStateService,
    private httpService: HttpService
  ) {}

  getProvince(country: string): Promise<Province> {
    return this.httpService
      .get<Province>(
        `/lfm-lookup/app/v1/geography/province?country=${country || 'TH'}`
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  getDistrict(provinceCode: string, country: string): any {
    return this.httpService
      .get<District>(
        `/lfm-lookup/app/v1/geography/province/${provinceCode}/district?country=${
          country || 'TH'
        }`
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  getSubDistrict(
    districtCode: string,
    provinceCode: string,
    country: string
  ): any {
    return this.httpService
      .get<SubDistrict>(
        `/lfm-lookup/app/v1/geography/province/${provinceCode}/district/${districtCode}/subdistrict?country=${
          country || 'TH'
        }`
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  getSubDistrictPrefill(
    subdistrictCode: string,
    districtCode: string,
    provinceCode: string,
    country: string
  ): any {
    return this.httpService
      .get<SubDistrict>(
        `/lfm-lookup/app/v1/geography/province/${provinceCode}/district/${districtCode}/subdistrict/${subdistrictCode}?country=${
          country || 'TH'
        }`
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  getBusinessInformation() {
    return this.appState.snapshot.businessInformation;
  }

  updateBusinessInformation(businessInformation: BusinessInformation) {
    this.appState.updateStateBusinessInformation(businessInformation);
  }

  getMailingAddress() {
    const mailingAddress = { ...this.appState.snapshot.mailingAddress };
    const newMailingAddress: BusinessInformation = {
      officeAddress1: mailingAddress.address1 || '',
      officeAddress1UpdFlag: String(mailingAddress.address1UpdFlag),
      officeAddress2: mailingAddress.address2 || '',
      officeAddress2UpdFlag: String(mailingAddress.address2UpdFlag),
      officeAddress3: mailingAddress.address3 || '',
      officeAddress3UpdFlag: String(mailingAddress.address3UpdFlag),
      officeAddress4: mailingAddress.address4 || '',
      officeAddress4UpdFlag: String(mailingAddress.address4UpdFlag),
      officeCountry: mailingAddress.country || '',
      officeName: '',
      officeNameUpdFlag: 'false',
      officePostalCode: mailingAddress.postCode || '',
      officePostalCodeUpdFlag: String(mailingAddress.postCodeUpdFlag),
      officeProvinceCode: mailingAddress.provinceCode || '',
      officeProvinceName: mailingAddress.provinceName || '',
      officeProvinceUpdFlag: String(mailingAddress.provinceUpdFlag),
      officeDistrictCode: mailingAddress.districtCode || '',
      officeDistrictName: mailingAddress.districtName || '',
      officeDistrictUpdFlag: String(mailingAddress.districtUpdFlag),
      officeSubDistrictCode: mailingAddress.subdistrictCode || '',
      officeSubDistrictName: mailingAddress.subdistrictName || '',
      officeSubDistrictUpdFlag: String(mailingAddress.subdistrictUpdFlag),
      officeCountryUpdFlag: 'false',
    };
    return newMailingAddress;
  }

  updateMailingAddress(mailingAddress: BusinessInformation) {
    const newMailingAddress: IMailingAddress = {
      address1: mailingAddress.officeAddress1,
      address1UpdFlag: Boolean(mailingAddress.officeAddress1UpdFlag),
      address2: mailingAddress.officeAddress2,
      address2UpdFlag: Boolean(mailingAddress.officeAddress2UpdFlag),
      address3: mailingAddress.officeAddress3,
      address3UpdFlag: Boolean(mailingAddress.officeAddress3UpdFlag),
      address4: mailingAddress.officeAddress4,
      address4UpdFlag: Boolean(mailingAddress.officeAddress4UpdFlag),
      provinceName: mailingAddress.officeProvinceName,
      provinceCode: mailingAddress.officeProvinceCode,
      provinceUpdFlag: Boolean(mailingAddress.officeProvinceUpdFlag),
      districtName: mailingAddress.officeDistrictName,
      districtCode: mailingAddress.officeDistrictCode,
      districtUpdFlag: Boolean(mailingAddress.officeDistrictUpdFlag),
      subdistrictName: mailingAddress.officeSubDistrictName,
      subdistrictCode: mailingAddress.officeSubDistrictCode,
      subdistrictUpdFlag: Boolean(mailingAddress.officeSubDistrictUpdFlag),
      postCode: mailingAddress.officePostalCode,
      postCodeUpdFlag: Boolean(mailingAddress.officePostalCodeUpdFlag),
      country: mailingAddress.officeCountry,
      countryUpdFlag: Boolean(mailingAddress.officeCountryUpdFlag),
    };
    this.appState.updateStateMailingAddress(newMailingAddress);
    this.appState.updateStateMailingAddressDraft(newMailingAddress);
  }

  getCustomerDetail() {
    return this.appState.snapshot.customerDetail;
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
