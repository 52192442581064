import { NAVIGATION_PATH } from '../constant/base.enum';

export interface IPromotion {
  version: string;
  cifNo: string;
  campNO: string;
  action: string;
  banner: IBanner;
  template: ITemplate;
  notificaitons: INotification;
}

export interface IBanner {
  navigationPathType?: NAVIGATION_PATH;
  navigationPath: string;
  homeUrl: string;
  title: string;
  message: string;
  startDate: string;
  endDate: string;
  priority: number;
  position: string[];
  channels: string[];
  isCallTemplate: boolean;
}

export interface ITemplate {
  templateName: string;
  title: string;
  urlToShare: string;
  mainImageUrl: string;
  tabs: ITab[];
  buttons?: IButton[];
  adPreviews?: IAdPreviews[];
  header?: IHeading;
}

export interface IHeading {
  heading?: string;
  subHeading?: string;
}

export interface IAdPreviews {
  imageUrl: string;
  buttons?: IButton[];
}

export interface ITab {
  tabHeader: string;
  content: IContent;
}

export interface IContent {
  heading: string;
  subHeading: string;
  paragraphs?: IParagraph[];
  points?: IParagraph[];
  footer: string;
}

export interface IParagraph {
  heading: string;
  message: string;
}

export interface IButton {
  caption: string;
  navigationPath: string;
  navigationPathType?: NAVIGATION_PATH;
}

export interface INotification {
  notiTempId: string | null;
  notiDate: string | null;
  values: IValue[];
}

export interface IValue {
  notiParam: string | null;
  notiParamValue: string | null;
}
