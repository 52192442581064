import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'web-di-ld-calculation-menu',
  templateUrl: './calculation-menu.component.html',
  styleUrls: ['./calculation-menu.component.scss'],
})
export class CalculationMenuComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onNavigateToCalculation() {
    this.onClick.emit();
  }
}
