export * from './base.interface';
export * from './personal.interface';
export * from './product-shelf.interface';
export * from './loan-apply.interface';
export * from './edit-info.interface';
export * from './marketing-consent.interface';
export * from './ncb-consnet.interface';
export * from './confirm-summary.interface';
export * from './save-application.interface';
export * from './flow.interface';
export * from './draft.interface';
export * from './promotion.interface';
export * from './promotion-banner.interface';
