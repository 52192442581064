import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './http.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { UiLoadingComponent } from './ui-loading/ui-loading.component';
import { UiLoadingService } from './ui-loading.service';
import { LoadingInterceptor } from './loading.interceptor';
import { environment } from '@environments/environment';
const provides = [HttpService, UiLoadingService];
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    ...provides,
    environment.mode !== 'local'
      ? [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
          },
        ]
      : [],
  ],
  declarations: [UiLoadingComponent],
  exports: [UiLoadingComponent],
})
export class AppsHttpModule {
  static forEnvironment(environment: any): ModuleWithProviders<AppsHttpModule> {
    return {
      ngModule: AppsHttpModule,
      providers: [{ provide: 'environment', useValue: environment }],
    };
  }
}
