import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'web-di-ld-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  @Input() displayButton: boolean = true;
  @Output() clickEvt = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  emitClickEvt() {
    this.clickEvt.emit();
  }
}
