<swiper
  [slidesPerView]="1"
  [spaceBetween]="10"
  [centeredSlides]="true"
  [loop]="isMulti"
  [loopAdditionalSlides]="1"
  [ngClass]="{
    mySwiper: true,
    'customer-swiper-container': isMulti
  }"
>
  <ng-container *transloco="let t">
    <ng-container *ngFor="let productDetail of items; let i = index">
      <ng-template swiperSlide>
        <div class="image-banner">
          <ng-container
            *ktbLoadImage="
              let safeUrl of productDetail.productImage;
              let complete = complete;
              let error = error
            "
          >
            <ng-container *ngIf="complete">
              <img
                [src]="safeUrl"
                class="banner-image"
                (click)="onClickButton(productDetail)"
              />
            </ng-container>

            <ng-container *ngIf="error">
              <img
                src="/assets/images/placeholders/Top_up_loan_productcard_placeholder_1X.png"
                class="banner-image"
                (click)="onClickButton(productDetail)"
              />
            </ng-container>

            <div
              class="card"
              (click)="onClickButton(productDetail)"
              id="divProductShelfTopupCard"
            >
              <div class="card-body">
                <div
                  class="product-title"
                  id="divProductShelfTopupTagLineTitle"
                >
                  {{ productDetail.tagLine }}
                </div>
                <web-di-ld-button
                  id="btnProductShelfTopupAcceptOffer"
                  [label]="t('topup.acceptOffer')"
                  [type]="'submit'"
                ></web-di-ld-button>
              </div>
              <div class="limit-time">
                {{ productDetail.limitTimeMsg }}
              </div>
              <div class="loan-detail">
                <div class="info-section-line"></div>
                <div class="description p-d-flex p-jc-between">
                  <div
                    class="title p-d-flex p-ai-center p-text-left"
                    style="flex: 2"
                    id="divProductShelfTopupTitlelMaxCreditLimit"
                  >
                    {{ t('loanProductShelf.topupDetail.maximumLimit') }}
                  </div>
                  <div
                    class="value p-d-flex p-ai-center p-jc-end"
                    style="flex: 1"
                  >
                    <div
                      class="bold big"
                      id="divProductShelfTopupMaxCreditLimit"
                    >
                      {{
                        productDetail?.maxCreditLimit
                          ? (productDetail?.maxCreditLimit
                            | currency: ' ':'symbol':'1.0-0')
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
                <!-- <div class="description p-d-flex p-jc-between">
                  <div
                    class="title"
                    id="divProductShelfTopupTitlelInterestRate"
                  >
                    {{ t('loanProductShelf.topupDetail.interestRate') }}
                  </div>
                  <div class="value">
                    <div class="bold" id="divProductShelfTopupInterestRate">
                      <span
                        class="on-discount"
                        id="spanProductShelfTopupInterestRate"
                        [ngSwitch]="productDetail?.interest?.rateType"
                      >
                        <ng-container *ngSwitchCase="LOAN_RATE_TYPE.FIXEDMAX">
                          {{
                            productDetail?.interest?.maxInterestRate
                              ? productDetail?.interest?.maxInterestRate + '%'
                              : '-'
                          }}
                        </ng-container>
                        <ng-container *ngSwitchCase="LOAN_RATE_TYPE.INDEXMAX">
                          {{
                            productDetail?.interest?.indexRateType
                              ? productDetail?.interest?.indexRateType +
                                (productDetail?.interest?.maxInterestRate! > 0
                                  ? '+'
                                  : '-')
                              : ''
                          }}{{
                            productDetail?.interest?.maxInterestRate
                              ? getAbsolute(
                                  productDetail?.interest?.maxInterestRate!
                                ) + '%'
                              : '-'
                          }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          {{
                            productDetail?.interest?.maxInterestRate
                              ? productDetail?.interest?.maxInterestRate + '%'
                              : '-'
                          }}
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</swiper>
