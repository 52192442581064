import { Injectable } from '@angular/core';
import { animationFrameScheduler, BehaviorSubject, Observable } from 'rxjs';
import { delay, finalize, map, observeOn, share, tap } from 'rxjs/operators';

@Injectable()
export class UiLoadingService {
  private readonly eventLoopAction = new BehaviorSubject<boolean[]>([]);
  isLoading = false;
  readonly isLoading$ = this.eventLoopAction.pipe(
    map((eventLoop) => eventLoop.length > 0),
    observeOn(animationFrameScheduler),
    tap((isLoading) => (this.isLoading = isLoading)),
    share()
  );

  constructor() {}

  showLoading<T>() {
    return (source: Observable<T>) =>
      new Observable<T>((observer) => {
        this.addTask();
        return source
          .pipe(finalize(() => this.removeTask()))
          .subscribe(observer);
      });
  }

  private addTask() {
    const eventLoop = this.eventLoopAction.value;
    eventLoop.push(true);
    this.eventLoopAction.next(eventLoop);
  }

  private removeTask() {
    const eventLoop = this.eventLoopAction.value;
    eventLoop.pop();
    this.eventLoopAction.next(eventLoop);
  }
}
