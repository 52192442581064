<swiper
  [pagination]="items.length > 1"
  [slidesPerView]="1"
  [spaceBetween]="10"
  [centeredSlides]="true"
  [autoplay]="{
    delay: autoSwipeTime * 1000,
    disableOnInteraction: false
  }"
  [speed]="1000"
  [loop]="items.length > 1"
  [loopAdditionalSlides]="1"
  class="mySwiper"
>
  <ng-container *ngFor="let item of items; let i = index">
    <ng-template swiperSlide>
      <div class="image-banner">
        <ng-container
          *ktbLoadImage="
            let safeUrl of item.imgURL;
            let complete = complete;
            let error = error
          "
        >
          <ng-container *ngIf="complete">
            <img
              [src]="safeUrl"
              class="banner-image"
              (click)="onClickBanner(item, i)"
            />
          </ng-container>

          <ng-container *ngIf="error">
            <img
              src="/assets/images/placeholders/banner-placeholder.svg"
              class="banner-image"
              (click)="onClickBanner(item, i)"
            />
          </ng-container>

          <ng-container
            *ktbLoadImage="
              let safeUrl of item.tagImgURL;
              let complete = complete;
              let error = error
            "
          >
            <ng-container *ngIf="complete">
              <img
                [src]="item.tagImgURL"
                alt=""
                class="tag-image"
                *ngIf="isShowTag(item)"
              />
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</swiper>
