<!-- <p-dropdown
  #dropdown
  [options]="value"
  [(ngModel)]="selectedValue"
  [optionLabel]="optionLabel"
  [showClear]="true"
>
</p-dropdown> -->

<ng-content></ng-content>

<div class="ion-action-sheet" *ngIf="dialogState != null">
  <div
    [@backdropFade]="dialogState"
    (@backdropFade.done)="endState($event)"
    class="backdrop"
    #backdrop
    (click)="close()"
  ></div>
  <div tabindex="0" class="sc-ion-action-sheet-md"></div>
  <div
    #dialog
    [@dialogOpen]="dialogState"
    class="action-sheet-wrapper ion-overlay-wrapper sc-ion-action-sheet-md"
    role="dialog"
  >
    <div class="action-sheet-container sc-ion-action-sheet-md">
      <div class="dropdown-container">
        <button
          type="button"
          class="action-sheet-button ion-activatable ion-focusable action-sheet-selected select-interface-option md sc-ion-action-sheet-md disabled"
        >
          <span class="action-sheet-button-inner sc-ion-action-sheet-md">{{
            headerText
          }}</span>
        </button>

        <div class="action-sheet-group sc-ion-action-sheet-md">
          <div
            *ngFor="let rowData of value; let i = index"
            class="p-d-flex p-flex-column"
            style="position: relative; height: 100%"
          >
            <div (click)="selectValue(rowData, $event)">
              <div class="p-d-flex p-flex-row" style="align-items: center">
                <div class="text-option">{{ rowData | displayValue }}</div>
                <img
                  *ngIf="rowData === selectedValue"
                  src="/assets/images/foundation-icon-simple-system-tick@3x.png"
                  width="24"
                  height="24"
                  alt=""
                  class="tick"
                />
              </div>
            </div>
            <div *ngIf="i < value.length - 1" class="info-section-line"></div>
          </div>
          <div
            style="height: 40px"
            class="action-sheet-button ion-activatable ion-focusable action-sheet- select-interface-option md sc-ion-action-sheet-md disabled"
          ></div>
        </div>

        <!-- <button
          type="button"
          class="action-sheet-button ion-activatable ion-focusable action-sheet- select-interface-option md sc-ion-action-sheet-md"
        >
          <span class="action-sheet-button-inner sc-ion-action-sheet-md"
            >Mute for a week</span
          ></button
        ><button
          type="button"
          class="action-sheet-button ion-activatable ion-focusable action-sheet- select-interface-option md sc-ion-action-sheet-md"
        >
          <span class="action-sheet-button-inner sc-ion-action-sheet-md"
            >Mute for a year</span
          >
        </button>
         -->
      </div>
      <!-- <div
        class="action-sheet-group action-sheet-group-cancel sc-ion-action-sheet-md"
      >
        <button
          type="button"
          (click)="close()"
          class="action-sheet-button ion-activatable ion-focusable action-sheet-cancel sc-ion-action-sheet-md"
        >
          <span class="action-sheet-button-inner sc-ion-action-sheet-md">{{
            cancelText
          }}</span>
        </button>
      </div> -->
    </div>
  </div>
</div>
