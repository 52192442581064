import {
  trigger,
  state,
  style,
  transition,
  animate,
  AnimationEvent,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChild,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'web-di-ld-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: [
    trigger('backdropFade', [
      state('open', style({ opacity: 0.32 })),
      state('close', style({ opacity: 0 })),
      transition('*=>open', animate('250ms ease-out')),
      transition('*=>close', animate('150ms ease-in')),
    ]),
    trigger('dialogOpen', [
      state('open', style({ transform: 'translate3d(0, 0, 0)' })),
      state('close', style({ transform: 'translate3d(0, 100%, 0)' })),
      transition('*=>open', animate('250ms ease-out')),
      transition('*=>close', animate('150ms ease-in')),
    ]),
  ],
})
export class SelectComponent implements OnInit, AfterContentInit, OnDestroy {
  @ContentChild(Dropdown, { static: true }) dropdown!: Dropdown;

  subscription = new Subscription();

  private valueAction = new BehaviorSubject<any[]>([]);
  value$ = this.valueAction.asObservable();
  // @ViewChild('dropdown', { static: true }) dropdown!: Dropdown;
  dialogState: string | null = null;
  @Input() headerText = 'Header';
  @Input() cancelText = 'Cancel';
  @Input() set value(val: any[]) {
    this.valueAction.next(val);
  }
  get value() {
    return this.valueAction.getValue();
  }
  @Input() optionLabel!: string;
  selectedValue: any | null = null;
  disabled = false;
  // onChange: (value: any) => void = () => {};
  // onTouch: () => void = () => {};
  constructor(@Inject(DOCUMENT) private document: Document) {}

  // writeValue(obj: any): void {
  //   this.selectedValue = obj;
  // }
  // registerOnChange(fn: any): void {
  //   this.onChange = fn;
  // }
  // registerOnTouched(fn: any): void {
  //   this.onTouch = fn;
  // }

  ngAfterContentInit() {
    this.dropdown.show = () => {
      this.open();
    };
    this.dropdown.hide = () => {
      this.close();
    };

    this.subscription.add(
      this.value$.subscribe((value) => {
        this.dropdown.options = value;

        this.dropdown.cd.detectChanges();
      })
    );
    this.dropdown.optionLabel = this.optionLabel;
    // this.dropdown.selectItem = (event, option) => {
    //   console.log(event, option);
    // };

    // console.log(this.dropdown);
  }

  // setDisabledState(disable: boolean) {
  //   this.disabled = disable;
  // }

  ngOnInit(): void {}

  open() {
    this.dialogState = 'open';
    this.document.body.style.overflow = 'hidden';
    this.selectedValue = this.dropdown.selectedOption;
  }
  close() {
    this.dialogState = 'close';
    this.document.body.style.overflow = 'auto';
    this.selectedValue = this.dropdown.selectedOption;
  }

  textBlur() {
    // this.onTouch();
  }

  endState(event: AnimationEvent) {
    if (event.fromState === 'open') {
      this.dialogState = null;
    }
  }

  selectValue(value: any, event: any) {
    this.selectedValue = value;
    this.dropdown.selectItem(event, value);
    this.dropdown.cd.detectChanges();
    // this.onChange(value);
    this.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
