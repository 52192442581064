<div class="center">
  <img src="{{ path }}" />
  <div class="title">
    <span>
      {{ title }}
    </span>
  </div>
  <div class="desc">
    <span class="desc-msg">
      {{ description }}
    </span>
  </div>
</div>
