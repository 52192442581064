export const pathUrl = {
  loanProductShelf: '/loan-product-shelf',
  loanApply: {
    apply: '/loan-apply',
    productDetail: '/loan-apply/product-detail',
    calculation: '/loan-apply/loan-calculation',
    topup: '/topup',
    topupRequestCredit: '/topup/request-credit',
    topupDetail: '/topup/detail',
    topupAdditional: '/topup/additional',
    topupConfirm: '/topup/confirm',
  },
  personalInformation: '/personal/personal-information',
  additionalInformation: '/personal/additional-information',
  editInformation: '/edit-information',
  confirmingDetails: '/confirming-details',
  marketingConsent: '/marketing-consent',
  ncbConsent: '/ncb-consent',
  aisKtbTermAndCon: '/ais-ktb-term-and-con',
  aisOtp: '/ais-otp',
  aisConsent: '/ais-consent',
  loanApplyVerifying: '/loan-apply-verify',
  loanApplyError: '/loan-apply-error',
  confirmSummary: '/confirm-summary',
  promotion: '/promotion',
  editMailingAddress: '/edit-mailing-address',
  ktbTermAndCon: '/ais-ktb-term-and-con',
};
