import { Component, Input } from '@angular/core';

@Component({
  selector: 'web-di-ld-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() label = '';

  constructor() {}
}
