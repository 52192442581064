import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { pathUrl } from '../../configs';
import {
  FLOW_STATE,
  IProductShelf,
  IPromotionBanner,
} from '../../core/interfaces';
import { HttpClient } from '@angular/common/http';
import { JsInterfaceService } from '../../core/services/shared/js-interface.service';
import {
  CustomDimensions,
  DeeplinkBody,
  EventGATagging,
} from '../../core/interfaces/js-interface.interface';
import { LoanProductService } from '../../core/services/loan-product/loan-product.service';
import { HttpService } from '@web-di-ld/apps/http';
import {
  BANNER_POSITION,
  BANNER_TYPE,
  BE_ERROR_CODE,
  LOAN_PRODUCT_TYPE,
  NAVIGATION_PATH,
} from '../../core/constant/base.enum';
import { CustomerService } from '../../core/services/loan-product/customer.service';
import { FlowService } from '../../core/services/shared/flow-service.service';
import { UiLoadingService } from 'libs/apps/http/src/lib/ui-loading.service';
import { BannersService } from '../../core/services/banners/banners.service';
import { NavigationService } from '../../core/services/shared/navigation.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NEW_LOANS, TOPUP_LOANS } from 'apps/di-ld/src/app/configs/app.config';
import { AppStateService } from '../../app-state.service';

@Component({
  selector: 'web-di-ld-loan-product-shelf',
  templateUrl: './loan-product-shelf.component.html',
  styleUrls: ['./loan-product-shelf.component.scss'],
})
export class LoanProductShelfComponent implements OnInit, OnDestroy {
  public loanProductList!: Array<IProductShelf>;
  public topupProductList!: Array<IProductShelf>;
  public topPromotionBanners!: Array<IPromotionBanner>;
  public bottomPromotionBanners!: Array<IPromotionBanner>;
  public autoSwipeTime!: number;
  public topSmartPromo!: Array<IPromotionBanner>;
  public bottomSmartPromo!: Array<IPromotionBanner>;
  public autoSwipeTimePromo!: number;
  public subscription = new Subscription();
  public BE_ERROR_CODE = BE_ERROR_CODE;
  private consent?: string;

  constructor(
    private translocoService: TranslocoService,
    public http: HttpClient,
    private jsInterfaceService: JsInterfaceService,
    public loanProductService: LoanProductService,
    private httpService: HttpService,
    private customerService: CustomerService,
    private flowService: FlowService,
    public uiLoadingService: UiLoadingService,
    private bannersService: BannersService,
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private appState: AppStateService
  ) {}

  public setActiveLang(lang: string) {
    this.translocoService.setActiveLang(lang);
  }

  public enterFullScreen(url?: string) {
    this.jsInterfaceService.enterFullScreen(url);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const productType = params['productType'];
      if (!productType) {
        this.httpService.clearProductType();
        this.loanProductService.clearProductDetail();
      }
    });
    this.flowService.setFlow(FLOW_STATE.SHELF, FLOW_STATE.SHELF);
    this.getVerifyToken();
    const event = {
      action: 'View',
      category: 'DigitalLending_LoanWebProductShelf',
    };
    this.gaTagging(event);
    this.appState.clearState();
  }

  getVerifyToken() {
    this.subscription.add(
      this.httpService.getVerifyToken().subscribe(async (verifyToken) => {
        const lang =
          verifyToken && verifyToken?.data?.language === 'th-TH' ? 'th' : 'en';
        this.setActiveLang(lang);

        const loanResponse = await this.loanProductService.getLoans();
        this.loanProductList = loanResponse.data
          ? loanResponse.data.products
          : [];
        this.topupProductList = loanResponse.data
          ? loanResponse.data.productsTopup?.filter(
              (pt: IProductShelf) => pt.isEligible
            )
          : [];
        this.getPromotionBanner(BANNER_TYPE.BANNER);
        this.getPromotionBanner(BANNER_TYPE.PROMO);

        if (location.pathname !== pathUrl.loanProductShelf) {
          const productType = await this.httpService.productType$.toPromise();
          const applilcationRef = await this.httpService.applicationRef$.toPromise();
          if (applilcationRef === undefined) {
            if (NEW_LOANS.includes(productType)) {
              if (productType === LOAN_PRODUCT_TYPE.SMARTMONEY) {
                const productSmartmoney = this.loanProductList.filter(
                  (product: IProductShelf) =>
                    product.productCode === LOAN_PRODUCT_TYPE.SMARTMONEY
                );
                this.loanProductService.updateLoanProduct(productSmartmoney[0]);
                this.enterFullScreen(pathUrl.aisKtbTermAndCon);
              } else {
                this.enterFullScreen(pathUrl.loanApply.apply);
              }
            } else if (TOPUP_LOANS.includes(productType)) {
              this.enterFullScreen(pathUrl.loanApply.topup);
              // this.router.navigateByUrl(pathUrl.loanApply.topup);
            }
          }
        }
      })
    );
  }

  async getCustomerDetail() {
    const customerDetail = await this.customerService.getCustomerDetail();
    if (customerDetail)
      this.customerService.updateCustomerDetail(customerDetail);
  }

  async getPromotionBanner(type: BANNER_TYPE) {
    const bannersRes = await this.bannersService.getPromotionBanners(
      type,
      FLOW_STATE.SHELF
    );
    this.autoSwipeTime = bannersRes.autoSwipeTime;
    if (
      type === BANNER_TYPE.BANNER &&
      bannersRes &&
      bannersRes.content.length > 0
    ) {
      this.topPromotionBanners = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'TOP'
      );
      this.bottomPromotionBanners = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'BOTTOM'
      );
    }

    if (
      type === BANNER_TYPE.PROMO &&
      bannersRes &&
      bannersRes.content.length > 0
    ) {
      this.topSmartPromo = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'TOP'
      );

      this.bottomSmartPromo = bannersRes.content.filter(
        (banner: IPromotionBanner) => banner.position === 'BOTTOM'
      );
    }
  }

  public onNavigateToCalculation() {
    const event = {
      action: 'Click',
      category: 'DigitalLending_LoanWebProductShelf',
      label: 'DigitalLending_LoanWebProductShelf_LoanCalculation',
      value: null,
    };

    this.gaTagging(event);
    this.enterFullScreen(pathUrl.loanApply.calculation);
  }

  public async onNavigateToProduct(
    product?: IProductShelf,
    isWebView?: boolean
  ) {
    if (
      product &&
      product?.productCode !== LOAN_PRODUCT_TYPE.SMARTMONEY &&
      product?.productCode !== LOAN_PRODUCT_TYPE._5PLUSOD &&
      product?.productCode !== LOAN_PRODUCT_TYPE._5PLUSTL
    ) {
      const productData: DeeplinkBody = {
        productType: product.productCode,
        marketCode: product.productMarketCode,
      };
      const event = {
        action: 'Click',
        category: 'DigitalLending_LoanWebProductShelf',
        label: 'DigitalLending_LoanWebProductShelf_LoanProduct',
        value: null,
      };

      const dimension = [
        {
          key: 'PRODUCT_TYPE',
          value: product.productCode,
        },
      ];
      this.gaTagging(event, dimension);
      this.jsInterfaceService.openDeepLink(productData);
    } else if (product && product.isEligible) {
      const event = {
        action: 'Click',
        category: 'DigitalLending_LoanWebProductShelf',
        label: 'DigitalLending_LoanWebProductShelf_LoanProduct',
        value: null,
      };

      const dimension = [
        {
          key: 'PRODUCT_TYPE',
          value: product.productCode,
        },
      ];
      // this.getCustomerDetail();
      this.gaTagging(event, dimension);
      product.isWebview = isWebView;
      this.loanProductService.updateLoanProduct(product);
      this.flowService.setState(FLOW_STATE.SHELF);
      if (product?.productCode === LOAN_PRODUCT_TYPE.SMARTMONEY) {
        this.enterFullScreen(pathUrl.aisKtbTermAndCon);
      } else {
        this.enterFullScreen(pathUrl.loanApply.apply);
      }
    }
  }

  public onNavigateToTopupProduct(product: IProductShelf) {
    if (product && product?.productCode.startsWith('TU_')) {
      const event = {
        action: 'Click',
        category: 'DigitalLending_LoanWebProductShelf',
        label: 'DigitalLending_LoanWebProductShelf_Topup',
        value: null,
      };

      const dimension = [
        {
          key: 'PRODUCT_TYPE',
          value: product.productCode,
        },
      ];
      this.gaTagging(event, dimension);
      this.loanProductService.updateLoanProduct(product);
      this.flowService.setState(FLOW_STATE.SHELF);
      this.enterFullScreen(pathUrl.loanApply.topup);
    }
  }

  public onCall() {
    const event = {
      action: 'Click',
      category: 'DigitalLending_LoanWebProductShelf',
      label: 'DigitalLending_LoanWebProductShelf_KrungthaiContactCenter',
      value: null,
    };

    this.gaTagging(event);
    window.open('tel:021111111');
  }

  public onClickPromotionBanner(item: IPromotionBanner) {
    item.bannerType = BANNER_TYPE.BANNER;
    this.onTrackingBanner(item);
    this.handleBannerNavigation(item);
  }

  public onClickSmartPromo(item: IPromotionBanner) {
    item.bannerType = BANNER_TYPE.PROMO;
    this.onTrackingBanner(item);
    this.handleBannerNavigation(item);
  }

  handleBannerNavigation(item: IPromotionBanner) {
    if (item.isCallTemplate) {
      this.bannersService.updatePromotionBanner(item);
      this.jsInterfaceService.enterFullScreen(pathUrl.promotion);
    } else {
      if (item.navigationPathType === NAVIGATION_PATH.SCREENWEB) {
        this.navigationService.goToScreenWeb(item.navigationPath);
      } else if (item.navigationPathType === NAVIGATION_PATH.DEEPLINK) {
        this.navigationService.goToDeepLink(item.navigationPath);
      } else if (item.navigationPathType === NAVIGATION_PATH.WEBVIEW_EXTERNAL) {
        this.navigationService.goToWebviewExternal(item.navigationPath);
      } else if (item.navigationPathType === NAVIGATION_PATH.WEBVIEW_INTERNAL) {
        this.navigationService.goToWebviewInternal(item.navigationPath);
      } else {
        this.navigationService.goToLink(item.navigationPath);
      }
    }
  }

  getLabelBanner(type?: string, position?: string) {
    if (type === BANNER_TYPE.BANNER && position === BANNER_POSITION.TOP) {
      return 'MktTopBanner';
    } else if (
      type === BANNER_TYPE.BANNER &&
      position === BANNER_POSITION.BOTTOM
    ) {
      return 'MktBottomBanner';
    } else if (type === BANNER_TYPE.PROMO && position === BANNER_POSITION.TOP) {
      return 'MktTopSquare';
    } else if (
      type === BANNER_TYPE.PROMO &&
      position === BANNER_POSITION.BOTTOM
    ) {
      return 'MktBottomSquare';
    } else {
      return;
    }
  }

  onTrackingBanner(item: IPromotionBanner) {
    const itemOrder = item.order ? String(item.order) : '';
    const elementLabel = this.getLabelBanner(item.bannerType, item.position);
    const event = {
      action: 'Click',
      category: 'DigitalLending_LoanWebProductShelf',
      label: `DigitalLending_LoanWebProductShelf_${elementLabel}`,
      value: null,
    };

    const dimension = [
      {
        key: 'CAMPAIGN_ID',
        value: item.campNo,
      },
      {
        key: 'ITEM_ORDER',
        value: itemOrder,
      },
      {
        key: 'POSITION',
        value: item.position,
      },
      {
        key: 'CAMPAIGN_TYPE',
        value: item.bannerType,
      },
    ];

    this.gaTagging(event, dimension);
  }

  gaTagging(event: EventGATagging, dimension?: CustomDimensions[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
