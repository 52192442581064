export interface IApplicaiton {
  appTemplVersion?: number; //integer
  submitRefNum?: string;
  employmentGrp: string;
  employmentGrpDesc: string;
  applicationRef: string;
  requestCreditLimit: number; //double
  // titleTh: string;
  // titleEn: string;
  // firstNameTh: string;
  // middleNameTh?: string;
  // surnameTh: string;

  // firstNameEn: string;
  // middleNameEn?: string;
  // surnameEn: string;

  // fullNameTh: string;
  // fullNameEn: string;
  officeName: string;
  // officeNameUpdFlag: string | boolean;
  officeSector: string;
  grossIncome: number;
  payrollAccount?: string;
  personalInformationPreFilled?: string;
  mailingAddress: IAddress;
  companyAddress: IAddress;
  ncb: {
    model: IConsent;
    consent: IConsent;
    ocpbConsent: IConsent;
  };
  productHolding: boolean;
  salesSheetUri: string;
  // dateOfBirth: string; // remove on 31 Oct 2023
  // mobile: string; // remove on 31 Oct 2023
  // email: string; // remove on 31 Oct 2023
  bankBranchCode?: string;
  bankBranchName?: string;
  costCenter?: string;
  pin?: string;
  e2eeSid?: string;
  mfaToken?: string;
  productMarketCode?: string;
  productTypeCode?: string;
  leadAccountNo?: string;
  disbursementAccountNo: string;
  directDebitAccountNo: string;
  disbursementAccountProductGroup: string;
  directDebitAccountProductGroup: string;
  disbursementAccountBranchCode: string;
  directDebitAccountBranchCode: string;
  disbursementAccountBranch: string;
  directDebitAccountBranch: string;
  directDebitAccountCostCenter: string;
  disbursementAccountOpenDt: string;
  directDebitAccountOpenDt: string;
  paymentTerm?: number; //integer
  paymentTermType?: string;
  monthlyInstallmentAmountMin?: number | null; //double
  monthlyInstallmentAmountMax?: number; //double
  gender?: string;
  maritalStatus?: string;
  maritalStatusUpdFlag?: string | boolean;
  maritalDescription?: string;
  educationCode?: string;
  educationDescription?: string;
  educationLevelUpdFlag?: string | boolean;
  currentResidenceDescription?: string;
  currentResidenceDuration?: IDuration;
  numberOfChildren?: number; //integer
  thaiPoliticianRelateFlag?: string | boolean;
  thaiPoliticianRelateFlagUpdFlag?: string | boolean;
  interPoliticianRelateFlag?: string | boolean;
  interPoliticianRelateUpdFlag?: string | boolean;
  interestRateMin: number | null; //double
  interestRateMax: number; //double
  payrollDate?: string; //datetime
  productCode?: string;
  citizenId: string;
  acceptanceDtm: Date | string;
  nationality: string;
  uploadDocumentFlag: string;
  allPayrollAcct?: string;
  maxPayrollAcctAvgIncome6M?: string;
  allPayrollAcctAvgIncome6M?: string;
  countryUpdFlag?: boolean;
  occupationGroupCode: string;
  occupationGroup: string;
  subOccupationCode: string;
  subOccupation: string;
  yearsOfWorkingPeriod: number;
  monthsOfWorkingPeriod: number;
  referralCode?: string;
}

export interface IAddress {
  address1: string;
  // address1UpdFlag: string | boolean;
  address2?: string;
  // address2UpdFlag: string | boolean;
  address3?: string;
  // address3UpdFlag: string | boolean;
  address4?: string;
  // address4UpdFlag: string | boolean;
  province: string;
  // provinceUpdFlag: string | boolean;
  district: string;
  // districtUpdFlag: string | boolean;
  subDistrict: string;
  // subDistrictUpdFlag: string | boolean;
  postalCode: string;
  // postalCodeUpdFlag: string | boolean;
  country: string;
  // countryUpdFlag: string | boolean;
}

export interface IDuration {
  year: number; //integer
  month: number; //integer
}

export interface IConsent {
  version?: number;
  acceptance: string; //ACCEPTED
}
