import { Directive, HostListener, Pipe, PipeTransform } from '@angular/core';

@Directive({
  selector: '[max-length]',
})
export class MaxLengthDirective {
  @HostListener('keyup', ['$event']) onKeyUp(event: any) {
    const str = event.target.value;
    if (str.length >= event.srcElement.maxLength) {
      event.target.value = str.substr(0, event.srcElement.maxLength);
    }
  }
}
