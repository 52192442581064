import { LOAN_PRODUCT_TYPE } from '../core/constant/base.enum';

export const NEW_LOANS: LOAN_PRODUCT_TYPE[] = [
  LOAN_PRODUCT_TYPE.SMARTMONEY,
  LOAN_PRODUCT_TYPE._5PLUSTL,
];

export const TOPUP_LOANS: LOAN_PRODUCT_TYPE[] = [
  LOAN_PRODUCT_TYPE.TOPUP_THUNGNGERN,
  LOAN_PRODUCT_TYPE.TOPUP_SMARTMONEY,
  LOAN_PRODUCT_TYPE.TOPUP_5PLUSTL,
];
