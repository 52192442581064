<div class="header">
  <web-di-ld-header
    title="{{ titleHeader() | transloco }}"
    share="{{ share }}"
  ></web-di-ld-header>
</div>

<div [ngClass]="{ container: titleHeader$ | async }">
  <router-outlet></router-outlet>
</div>
<web-di-ld-ui-loading
  *ngIf="uiLoadingService.isLoading$ | async"
></web-di-ld-ui-loading>

<web-di-ld-dialog-error></web-di-ld-dialog-error>
