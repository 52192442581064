import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';

import { throwError } from 'rxjs';

import {
  Consent,
  CustomerDetail,
  IApplicaiton,
  MarketingConsent,
  ProductDetail,
} from '../../interfaces';
import { AppStateService } from '../../../app-state.service';
import { HttpService } from '@web-di-ld/apps/http';
import { DialogErrorHandlerService } from '../../../shared/error-handler/dialog-error-handler.service';
import {
  KtbXAisConsentList,
  KtbXAisTermAndCondition,
} from '../../interfaces/ktb-x-ais-term-and-condition.interface';

// service
@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  public errorCode = '';
  public displayError = false;

  constructor(
    private httpClient: HttpClient,
    public appState: AppStateService,
    public httpService: HttpService,
    private dialogService: DialogErrorHandlerService
  ) {}

  getMarketConsent(): any {
    return this.httpService
      .get<MarketingConsent>(
        `/lfm-applications/app/v1/application/model-consent`
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        // this.handleError(error);
        return [];
      });
  }

  getKtbxAisTermAndCondition(): any {
    return this.httpService
      .get<KtbXAisTermAndCondition>(`/lfm-ais/app/v1/ktb/consent`)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
        return [];
      });
  }

  postKtbXAisTermAndCondition(body: KtbXAisConsentList): any {
    return this.httpService
      .post(`/lfm-ais/app/v1/ktb/answer`, body)
      .toPromise()
      .then((response: any) => {
        console.log('postKtbXAisTermAndCondition response: ', response);
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  postNcbConsent(customerDetail: CustomerDetail): any {
    return this.httpService
      .post(`/lfm-applications/app/v1/application/consent-ncb-draft`, {
        // titleTh: customerDetail.titleTh, // remove on 31 Oct 2023
        // firstNameTh: customerDetail.firstNameTh, // remove on 31 Oct 2023
        // surnameTh: customerDetail.surnameTh, // remove on 31 Oct 2023
        // custBirthDate: customerDetail.dateOfBirth, // remove on 31 Oct 2023
        // mobileNumber: customerDetail.mobileNumber, // remove on 31 Oct 2023
        productTypeCode: this.getStateProductDetail().productTypeCode,
        productMarketCode: this.getStateProductDetail().productMarketCode,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  postNcbConsentNext(customerDetail: CustomerDetail): any {
    return this.httpService
      .post(
        `/api/applications/v1/applications/consent-ncb-draft`,
        {
          titleTh: customerDetail.titleTh,
          firstNameTh: customerDetail.firstNameTh,
          surnameTh: customerDetail.surnameTh,
          custBirthDate: customerDetail.dateOfBirth,
          mobileNumber: customerDetail.mobileNumber,
          productTypeCode: this.getStateProductDetail().productTypeCode,
          productMarketCode: this.getStateProductDetail().productMarketCode,
        },
        true
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  postAisConsent(isAccept: boolean): any {
    return this.httpService
      .post(`/lfm-lfm-ais/app/v1/ktb/term-and-condition`, {
        mobileNumber: '1234',
        value: isAccept,
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  getStateConsent(): any {
    return this.appState.snapshot.application.consent;
  }

  getStateStatePersonalInformation(): any {
    return this.appState.snapshot.personalInformation;
  }

  getStateLoanApplication(): any {
    return this.appState.snapshot.loanApplication;
  }

  getStateProductDetail(): ProductDetail {
    return this.appState.snapshot.productDetail;
  }

  getCustomerDetail(): any {
    return this.appState.snapshot.customerDetail;
  }

  updateConsent(consent: Consent) {
    this.appState.updateStateConsent(consent);
  }

  saveApplicaiton(application: IApplicaiton) {
    return this.httpService
      .post(`/lfm-applications/app/v2/application/save`, application, false)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        if (error.status === HttpStatusCode.InternalServerError) {
          this.setError(error.error.code, error.error.message);
        }
        return null;
      });
  }

  setError(errorCode: string, message: string = '') {
    this.dialogService.setError('', errorCode, message);
  }
}
