import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'web-di-ld-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent implements OnInit {
  @Output() onCall = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  call() {
    this.onCall.emit();
  }
}
