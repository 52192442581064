import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslocoRootModule } from '../../core/transloco-root.module';
import { UiModule } from '../../shared/components/ui/ui.module';

import { LoanProductShelfRoutingModule } from './loan-product-shelf-routing.module';
import { LoanProductShelfComponent } from './loan-product-shelf.component';

import { CalculationMenuComponent } from './components/calculation-menu/calculation-menu.component';
import { ContactInformationComponent } from './components/contact-information/contact-information.component';
import { ProductsTopupComponent } from './components/products-topup/products-topup.component';
@NgModule({
  declarations: [
    LoanProductShelfComponent,
    ContactInformationComponent,
    CalculationMenuComponent,
    ProductsTopupComponent,
  ],
  imports: [
    CommonModule,
    LoanProductShelfRoutingModule,
    UiModule,
    TranslocoRootModule,
  ],
  exports: [
    LoanProductShelfComponent,
    CalculationMenuComponent,
    ProductsTopupComponent,
  ],
})
export class LoanProductShelfModule {}
