import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@web-di-ld/apps/http';
import { EMPTY } from 'rxjs';
import { AppStateService } from '../../../app-state.service';
import { BANNER_TYPE } from '../../constant/base.enum';
import { FLOW_STATE, IBaseBanner, IPromotionBanner } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class BannersService {
  constructor(
    private httpService: HttpService,
    private appState: AppStateService,
    private httpClient: HttpClient
  ) {}

  async getPromotionBanners(
    type: BANNER_TYPE,
    page: FLOW_STATE
  ): Promise<IBaseBanner> {
    return this.httpService
      .get<IBaseBanner>(
        `/lfm-banners/app/v1/campaigns?type=${type}&page=${page}`
      )
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch(async (error) => {
        console.error(error);
      });
  }

  async getPromotionBannersMock(): Promise<IBaseBanner> {
    return this.httpClient
      .get<IBaseBanner>(`/assets/data/mock-data/get-banner.json`)
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }

  public updatePromotionBanner(promotionBanner: IPromotionBanner) {
    this.appState.updateStatePromotionBanner(promotionBanner);
  }

  public updateSmartPromo(smartPromo: IPromotionBanner) {
    this.appState.updateStateSmartPromo(smartPromo);
  }

  async getSmartPromoMock(): Promise<IBaseBanner> {
    return this.httpClient
      .get<IBaseBanner>(`/assets/data/mock-data/smart-promo.json`)
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }
}
