<p-card>
  <div class="p-d-flex p-ai-center p-jc-center">
    <div>
      <img src="assets/images/vayuInformation@3x.png" alt="" />
    </div>
    <div class="information-message" id="divProductShelfInformationMessage">
      {{ 'loanProductShelf.informationMessage' | transloco }}
      <span class="link" (click)="call()" id="divProductShelfContactNumber"
        >02-111-1111</span
      >
    </div>
  </div>
</p-card>
