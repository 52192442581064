<ng-container *transloco="let t">
  <div class="verify-container">
    <web-di-ld-apply-error
      path="/assets/images/foundationIconSimpleObjectStatusesProcessing.png"
      title="{{ t('applyVerify.title') }}"
      description="{{ t('applyVerify.description') }}"
    ></web-di-ld-apply-error>
    <ng-container *ngIf="displayButton">
      <web-di-ld-button
        name="verify-button"
        label="{{ t('common.cancel') }}"
        (click)="emitClickEvt()"
      ></web-di-ld-button>
    </ng-container>
  </div>
</ng-container>
