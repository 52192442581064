import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './core/template/main/main.component';
import { VersionComponent } from './pages/version/version.component';
import { pathUrl } from './configs';

const removeSlash = (path: string) => path.replace('/', '');

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: removeSlash(pathUrl.aisKtbTermAndCon),
        loadChildren: () =>
          import(
            './pages/ais-ktb-term-and-con/ais-ktb-term-and-con.module'
          ).then((m) => m.AisKtbTermAndConModule),
      },
      {
        path: removeSlash(pathUrl.aisOtp),
        loadChildren: () =>
          import('./pages/ais-otp/ais-otp.module').then((m) => m.AisOtpModule),
      },
      {
        path: removeSlash(pathUrl.aisConsent),
        loadChildren: () =>
          import('./pages/ais-consent/ais-consent.module').then(
            (m) => m.AisConsentModule
          ),
      },
      {
        path: 'loan-product-shelf',
        loadChildren: () =>
          import('./pages/loan-product-shelf/loan-product-shelf.module').then(
            (m) => m.LoanProductShelfModule
          ),
      },
      {
        path: 'ncb-consent',
        loadChildren: () =>
          import('./pages/ncb-consent/ncb-consent.module').then(
            (m) => m.NcbConsentModule
          ),
      },
      {
        path: 'marketing-consent',
        loadChildren: () =>
          import('./pages/marketing-consent/marketing-consent.module').then(
            (m) => m.MarketingConsentModule
          ),
      },
      {
        path: 'confirming-details',
        loadChildren: () =>
          import('./pages/confirming-details/confirming-details.module').then(
            (m) => m.ConfirmingDetailsModule
          ),
      },
      {
        path: 'edit-information',
        loadChildren: () =>
          import(
            './pages/personal/edit-information/edit-information.module'
          ).then((m) => m.EditInformationModule),
      },
      {
        path: 'edit-mailing-address',
        loadChildren: () =>
          import(
            './pages/personal/edit-information/edit-information.module'
          ).then((m) => m.EditInformationModule),
      },
      // {
      //   path: 'additional-information',
      //   loadChildren: () =>
      //     import(
      //       './pages/personal/additional-information/additional-information.module'
      //     ).then((m) => m.AdditionalInformationModule),
      // },
      // {
      //   path: 'personal-information',
      //   loadChildren: () =>
      //     import(
      //       './pages/personal/personal-information/personal-information.module'
      //     ).then((m) => m.PersonalInformationModule),
      // },
      {
        path: 'loan-apply',
        loadChildren: () =>
          import('./pages/loan-apply/loan-apply.module').then(
            (m) => m.LoanApplyModule
          ),
      },
      {
        path: 'personal',
        loadChildren: () =>
          import('./pages/personal/personal-step/personal-step.module').then(
            (m) => m.PersonalStepModule
          ),
      },
      {
        path: 'rating',
        loadChildren: () =>
          import('./pages/confirm-summary/confirm-summary.module').then(
            (m) => m.ConfirmSummaryModule
          ),
      },
      {
        path: 'topup',
        loadChildren: () =>
          import('./pages/topup/topup.module').then((m) => m.TopupModule),
      },
    ],
  },
  {
    path: 'promotion',
    loadChildren: () =>
      import('./pages/promotion/promotion.module').then(
        (m) => m.PromotionModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
