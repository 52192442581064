import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { DialogErrorHandlerService } from '../dialog-error-handler.service';

@Component({
  selector: 'web-di-ld-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss'],
})
export class DialogErrorComponent implements OnInit {
  constructor(
    public dialogErrorHandlerService: DialogErrorHandlerService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.setDefaultErrorMessage();
  }

  setDefaultErrorMessage() {
    const translocoSubscription = new Subscription();

    translocoSubscription.add(
      this.translocoService
        .selectTranslation()
        .subscribe((translation: any) => {
          this.dialogErrorHandlerService.defaultErrorMessage =
            translation['applyError.6.title'];
          translocoSubscription.unsubscribe();
        })
    );
  }
}
