import { LOAN_PRODUCT_TYPE, LOAN_RATE_TYPE } from '../constant/base.enum';

export interface IProductShelf {
  isEligible: boolean;
  applicationRefId?: string;
  productCode: LOAN_PRODUCT_TYPE;
  productTypeCode: string;
  productName: string;
  tagLine?: string;
  productImage: string;
  personalizationCreditLimitLevel: string;
  personalizationPriceLevel: string;
  productMarketCode: string;
  haveTagNew: boolean;
  haveTagHot: boolean;
  haveCountDown: boolean;
  countDownEndDate?: string;
  maxCreditLimit: number;
  interest: LoanInterestRate;
  maxPaymentTerm: number;
  effectiveDate: string;
  expirationDate: string;
  campaignId?: string;
  limitTimeMsg?: string;
  isWebview?: boolean;
}

export interface ILoanOffersReq {
  campaignId: string;
  productNameEn: string;
  productNameTh: string;
  maxCreditLimit: number;
  minCreditLimit: number;
  maxInterestRate: number;
  minInterestRate: number;
}

export interface ILoanOffers {
  campaignId: string;
  expirationDate: string;
  navPath: string;
  productMarketCode: string;
  productNameEn: string;
  productNameTh: string;
  productType: LOAN_PRODUCT_TYPE;
  productTypeCode: string;
}

export interface LoanInterestRate {
  rateType?: LOAN_RATE_TYPE;
  indexRateType?: string;
  minInterestRate?: any;
  maxInterestRate?: number;
  minSpecialRate?: number;
  maxSpecialRate?: number;
  enableSpecial: boolean;
}
