import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select/select.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DisplayValuePipe } from './select/display-value.pipe';
// import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, DropdownModule],
  declarations: [SelectComponent, DisplayValuePipe],
  exports: [SelectComponent, DropdownModule],
})
export class UiMobileSelectModule {}
